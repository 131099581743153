import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// UTILS
// API
// ACTIONS
// COMPONENTS
import Header from 'components/HeaderFTL'
import UserProfile from 'components/UserProfile/UserProfile'
import DeletionAccount from 'components/DeletionAccount'
import FormEditUser from 'components/FormEditUser'
// CONSTANTS
// COMMON
import CommonUtils from 'utils/common'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer';
import { getParamFromURL, removeListParamOnURL } from 'utils/booking/common'
import paths from 'routers/paths'
import { showLoading } from 'assets/javascripts/webapp-v2/common'
import { FT_DEL_ACC, DEL_ACC } from 'constants/userProfile'
import { appsFlyerSetCUID } from 'utils/trackingAppsFlyer'
// ASSETS

const NavBarContainer = () => {
  const dispatch = useDispatch()
  const currentCustomer = useSelector(state => state.currentCustomer)
  const extraInfos = useSelector(state => state.extraInfos)
  const authenticationToken = window.localStorage.getItem('access_token') || currentCustomer.authentication_token || ''
  const lastLoginEmployId = currentCustomer.last_login_employ_id || null
  const dropdownAreas = useSelector(state => state.extraInfos?.dropdownAreas)
  const listLanguage = useSelector(state => state.extraInfos?.listLanguage)

  const [showDeletionAccountPopup, setShowDeletionAccountPopup] = useState(false)

  const linkSetting = {
    termsAndConditionsLink: extraInfos.tc_link || '',
    privacyPolicyLink: extraInfos.privacy_policy_link || '',
    hideAreaSelector: extraInfos.hide_area_selector,
  }
  const [isShowEdit, setIsShowEdit] = useState(false)

  useEffect(() => {
    const isFeatDeletionAccount = getParamFromURL(FT_DEL_ACC) === 'true'
    if(isFeatDeletionAccount && currentCustomer.id) {
      setShowDeletionAccountPopup(true)
    }
  }, [])

  useEffect(() => {
    if (!authenticationToken) {
      dispatch(
        currentCustomerActionsCreator.updateCustomer({ show_phone_number_to_driver: true })
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken])

  useEffect(() => {
    if (currentCustomer.id && currentCustomer.name) {
      const {
        id, last_name: lastName, first_name: firstName,
        name, email, phone, current_company_id: companyID, country_code: countryCode
      } = currentCustomer
      const removePlusInPhone = phone && phone.slice(1)
      appsFlyerSetCUID(currentCustomer.id);
      CommonUtils.moengageUserAttribute(id, firstName, lastName, name, email,
        removePlusInPhone, companyID, countryCode, false)
    } else {
      appsFlyerSetCUID('')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer.id, currentCustomer.name])

  const onCloseDeletionAccount = () => {
    removeListParamOnURL([DEL_ACC])
    setShowDeletionAccountPopup(false)
  }

  return (
    <React.Fragment>
      <Header
        currentCustomer={currentCustomer}
        customerSignedIn={authenticationToken}
        isBusinessSession={!!lastLoginEmployId}
        dropdownAreas={dropdownAreas}
        listLanguage={listLanguage}
        area={extraInfos}
        linkSetting={linkSetting}
        extraInfos={extraInfos}
        employs={currentCustomer.employs}
      />
      {
        currentCustomer.isShow && (
          <UserProfile
            currentCustomer={currentCustomer}
            setIsShowEdit={setIsShowEdit}
          />
        )
      }
      {
        isShowEdit && (
          <FormEditUser
            currentCustomer={currentCustomer}
            setIsShowEdit={setIsShowEdit}
          />
        )
      }
      {
        showDeletionAccountPopup && (
          <DeletionAccount
            currentCustomer={currentCustomer}
            onClosePopup={onCloseDeletionAccount}
          />
        )
      }
    </React.Fragment>
  )
}

export default NavBarContainer

export const GMAPS_BROWSER_KEY = process.env.REACT_APP_GMAPS_BROWSER_KEY
export const GMAPS_API_VERSION = process.env.REACT_APP_GMAPS_API_VERSION

export const FIREBASE_CONFIG = process.env.REACT_APP_FIREBASE_CONFIG
export const FIREBASE_WEB_PUSH_KEY = process.env.REACT_APP_FIREBASE_WEB_PUSH_KEY
export const FIREBASE_ENV = process.env.REACT_APP_FIREBASE_ENV
export const WEBHOOK_ENV = process.env.REACT_APP_FIREBASE_ENV

export const TWILIO_PREFIX = process.env.REACT_APP_TWILIO_PREFIX
export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_ID
export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY
export const GOOGLE_ANALYTIC_CODE = process.env.REACT_APP_GOOGLE_ANALYTIC_CODE
export const GOOGLE_PLAY_URL = 'http://m.onelink.me/761f61b6'
export const APP_STORE_URL = 'http://m.onelink.me/67b722c'
export const GOOGLE_GEOCODER_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${GMAPS_BROWSER_KEY}`
export const GOOGLE_MAP_LOAD_URL = `https://maps.googleapis.com/maps/api/js?key=${GMAPS_BROWSER_KEY}&v=${GMAPS_API_VERSION}&libraries=places&callback=mapLoaded`
export const GOOGLE_PLAY_URL_TRANSPORTIFY = 'https://play.google.com/store/apps/details?id=com.transportify.user'
export const APP_STORE_URL_TRANSPORTIFY = 'https://itunes.apple.com/ph/app/transportify/id1153482546'
export const MAP_STYLE = process.env.REACT_APP_MAP_STYLE && JSON.parse(process.env.REACT_APP_MAP_STYLE)
// export const NOTIFICATION_SOUND = require("../../../assets/audio/FPD_notification_sound.mp3")
export const SENTRY_PUBLIC_DSN = process.env.SENTRY_PUBLIC_DSN
export const DELIVEREE_MARKETING_URL = 'https://www.deliveree.com'
export const TRANSPORTIFY_MARKETING_URL = 'https://www.transportify.com.ph'
export const DELIVEREE_AUTHENTICATION_SERVICE_URL = process.env.REACT_APP_DELIVEREE_AUTHENTICATION_SERVICE_URL
export const DELIVEREE_PLACES_SERVICE_URL = process.env.REACT_APP_DELIVEREE_PLACES_SERVICE_URL
export const SHOWROUTE_CURVE = 0.25
export const SHOWROUTE_SECTION = 40
export const JOTFORM_URL_VERIFY_DEPOSIT = process.env.REACT_APP_JOTFORM_URL_VERIFY_DEPOSIT

export const LAT_LNG_SEARCH_PATTERN = '^([-+]?[0-9]+([.]\\d+)?)(\\s*,\\s*|\\s+)([-+]?[0-9]+([.]\\d+)?)$'
export const TALLY_API_URL = process.env.REACT_APP_TALLY_API_URL

export const ADYEN_CLIENT_KEY = process.env.ADYEN_CLIENT_KEY
// Need to remove key `staging` when test done Adyen
export const ADYEN_ENV = ['staging', 'production'].includes(process.env.NODE_ENV) ? 'live' : 'test'

// Ltl Cargopedia
export const NODE_ENV = process.env.NODE_ENV
export const API_DOC_URL = 'https://developers.deliveree.com/'
export const LTL_API_URL = process.env.REACT_APP_LTL_API_URL
export const LTL_CUSTOMER_DOMAIN = process.env.REACT_APP_LTL_CUSTOMER_DOMAIN
export const LTL_CUSTOMER_DOMAIN_PH = process.env.REACT_APP_LTL_CUSTOMER_DOMAIN_PH
export const FAVORITE_DRIVER_EN_URL = 'https://www.deliveree.com/id/en/blog-favorite-and-ban-driver/'
export const FAVORITE_DRIVER_ID_URL = 'https://www.deliveree.com/id/blog-favoritkan-dan-blokir-pengemudi/'
export const BUNDLE_EST_URL = process.env.BUNDLE_EST_URL
export const DEBUG_LOGS_MOENGAGE = process.env.REACT_APP_DEBUG_LOGS_MOENGAGE
export const MOENGAGE_APP_ID_TRANSPORTIFY = process.env.REACT_APP_MOENGAGE_APP_ID_TRANSPORTIFY
export const MOENGAGE_APP_ID = process.env.REACT_APP_MOENGAGE_APP_ID
export const REACT_APP_SENTRY = process.env.REACT_APP_SENTRY
export const REACT_APP_SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV
export const PUBLIC_URL = process.env.PUBLIC_URL

// Payment
export const API_GATEWAY = process.env.REACT_APP_API_GATEWAY
export const PAYMENT_API = process.env.REACT_APP_PAYMENT_API

export const TRANSPORTIFY_WEBAPP_HOST = process.env.REACT_APP_TRANSPORTIFY_WEBAPP_HOST
export const DELIVEREE_WEBAPP_HOST = process.env.REACT_APP_DELIVEREE_WEBAPP_HOST

export const LCL_CUSTOMER_URL = process.env.REACT_APP_LCL_CUSTOMER_URL
export const E_WALLET_URL = process.env.REACT_APP_E_WALLET_URL

// AWS MAPS
export const MAP_STYLE_URL = process.env.REACT_APP_MAP_STYLE_URL

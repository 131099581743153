
import {
  FULL_DAY,
  LONG_HAUL,
  NOW,
} from 'constants/bookingConstants'
import { EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS } from 'constants/extraServiceConstants'
import { isEditBooking } from './common'
import _ from 'lodash'

const LONG_HYPHEN = '&mdash;'

export const TallyUtils = {
  validateDataForRecalculatingTally({ locations, vehicleTypeID, timeType }) {
    const validLocations = locations.filter(location => (location.lat && location.lng))
    const isFullDay = timeType === FULL_DAY
    if (isFullDay) {
      return validLocations.length > 0 && vehicleTypeID
    }

    return validLocations.length > 1 && vehicleTypeID
  },

  // We extract price, distance, transitTime based on some condition combination
  calculateTallyData(
    { locations, vehicleTypeID, timeType }, { booking = {}, specificStep, vehicleType } = {}, isLiveTracking = false
  ) {
    const {
      total_distance: distance,
      transit_time: transitTime,
    } = booking
    let price
    if (specificStep === 1 || specificStep === 2) {
      const step2Price = (booking.step2Price || 0)
      price = booking.step1Price + step2Price
    } else {
      price = booking.subtotal
    }

    const numberOfValidLocation = locations.filter(({ lat, lng }) => (lat && lng)).length
    const hasAtLeast2ValidLocation = numberOfValidLocation > 1
    let result
    const isFullDay = timeType === FULL_DAY

    if (!vehicleTypeID && !timeType && !hasAtLeast2ValidLocation) {
      result = {
        price: null,
        distance: null,
        transitTime: null,
      }
    } else if (!vehicleTypeID && !timeType && hasAtLeast2ValidLocation) {
      result = {
        price: LONG_HYPHEN,
        distance,
        transitTime: LONG_HYPHEN,
      }
      // case E
    } else if (vehicleTypeID && !timeType && !hasAtLeast2ValidLocation) {
      result = {
        price: null,
        distance: null,
        transitTime: null,
      }
      // case F
    } else if (vehicleTypeID && timeType && !hasAtLeast2ValidLocation) {
      // for timeType of full-day we need to check more condition
      if (isFullDay && numberOfValidLocation === 1) {
        result = {
          price,
          distance: LONG_HYPHEN,
          transitTime,
        }
      } else {
        result = {
          price: null,
          distance: null,
          transitTime: null,
        }
      }
      // case G
    } else if (vehicleTypeID && !timeType && hasAtLeast2ValidLocation) {
      result = {
        price: LONG_HYPHEN,
        distance,
        transitTime,
      }
      // case H
    } else if (vehicleTypeID && timeType && hasAtLeast2ValidLocation) {
      result = {
        price,
        distance,
        transitTime,
      }
    }

    const { settings } = vehicleType || {}
    if ((settings && !settings.transit_time_enabled && !isLiveTracking)) {
      result.transitTime = 0
    }

    return result
  },

  calculateTallyPrice({ locations, vehicleTypeID, timeType }, { booking = {}, specificStep, vehicleType } = {}) {
    const { price } = this.calculateTallyData(
      { locations, vehicleTypeID, timeType },
      { booking, specificStep, vehicleType }
    )

    return price
  },

  calculateCPODFee(locations, codPodFees) {
    const hasNeedPOD = locations.some(item => item.need_pod)

    return hasNeedPOD ? (+codPodFees || 0) : 0
  },

  calculateCustomReimbursementsCost(customReimbursements) {
    const cost = _.filter(
      customReimbursements,
      reimbursement => reimbursement.selected_amount >= 1
    ).reduce(
      (initial, reimbursement) => {
        let amount = parseInt(reimbursement.amount || 0, 10)
        const userInputAmount = parseInt(reimbursement.user_input_amount, 10)
        if (reimbursement.allow_user_to_enter_amount && userInputAmount && userInputAmount > 0) {
          amount = userInputAmount
        }
        const selectedAmount = parseInt(reimbursement.selected_amount, 10)

        return initial + amount * selectedAmount
      },
      0,
    )
    return cost
  },

  getTotalWorkingDays(outOfServiceStatus, bookAgainDetails) {
    if (outOfServiceStatus && outOfServiceStatus.long_haul_pickup) {
      return outOfServiceStatus.long_haul_pickup.total_working_days
    } else if (isEditBooking() && bookAgainDetails.distance_fee_details) {
      return bookAgainDetails.distance_fee_details.total_working_days
    }
    return 1
  },
  /**
   * Extra services include, it is cost of step2
   *  - extra requirement such as Extra helper, Special helper, Toll & Parking Included,...
   *  - full day booking: extra days, over times
   */
  calculateExtraServicesPrice(
    {
      extraServices,
      booking = {}, timeType,
      bookAgainDetails, outOfServiceStatus,
      locations = [],
    } = {}
  ) {
    const {
      extraRequirements = [],
      fullDayPricing = {},
      extraRequirementsNegativePosition = [],
      customReimbursements = []
    } = extraServices
    /* eslint-disable no-param-reassign */
    const extraRequirementLocations = locations.reduce(
      (initial, { extra_requirement_locations: _extraRequirementLocations }) => {
        _extraRequirementLocations = _extraRequirementLocations || []

        return [
          ...initial,
          ..._extraRequirementLocations,
        ]
      },
      []
    ).filter(({ selected_amount: selectedAmount }) => (selectedAmount))
    const extraRequirementLocationsCost = extraRequirementLocations.reduce(
      (initial, item) => {
        const { selected_amount: selectedAmount, unit_price: unitPrice } = item

        return initial + unitPrice * selectedAmount
      },
      0,
    )
    const extraRequirementIdsForLonghaul = extraRequirementLocations.map(item => (item.extra_requirement_id))
    const extraRequirementsCost = extraRequirements
      // exclude cost of extra requirement for long haul which store in location
      .filter(item => (!extraRequirementIdsForLonghaul.includes(item.id) && !item.hide_from_services_selection))
      .reduce((initial, item) => {
        let price

        // 0, undefined, null
        if (!item.selected_amount) {
          return initial
        }

        const {
          is_flat: isFlat,
          is_per_unit_fee: isPerUnitFee,
          pricing_method: pricingMethod,
        } = item

        if (pricingMethod === EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS) {
          price = item.selectedPricing ? item.selectedPricing.fees : 0
        } else if (timeType === FULL_DAY) {
          price = item.unit_price
          if (!isFlat) {
            price *= item.selected_amount * fullDayPricing.selected_amount
          }
        } else if (timeType === LONG_HAUL) {
          price = item.unit_price
          if (isPerUnitFee) {
            // LongHaul - Per Unit Fee
            price *= item.selected_amount
          } else if (!isFlat) {
            price *= item.selected_amount * this.getTotalWorkingDays(outOfServiceStatus, bookAgainDetails)
          }
        } else {
          price = item.unit_price * item.selected_amount
        }

        return initial + price
      }, 0)

    const fullDayExtraCost = extraRequirementsNegativePosition
      .filter(item => !item.hide_from_services_selection)
      .reduce((initial, item) => {
        const {
          unit_price: unitPrice = 0,
          selected_amount: selectedAmount = 0,
        } = item

        return initial + (+unitPrice * (+selectedAmount) * (+fullDayPricing.selected_amount))
      }, 0)

    const {
      unit_price: fullDayPricingUnitPrice = 0,
      selected_amount: fullDayPricingAmount = 0,
    } = fullDayPricing

    const codPodFees = this.calculateCPODFee(locations, booking.cod_pod_fees)
    const customReimbursementCost = this.calculateCustomReimbursementsCost(customReimbursements)

    return extraRequirementsCost
      // exclude the first day which is calculated at step1
      + (+fullDayPricingUnitPrice * (+fullDayPricingAmount - 1))
      + fullDayExtraCost + codPodFees
      + extraRequirementLocationsCost
      + customReimbursementCost
  },

  isEnabledTally({ extraInfos, vehicleType } = {}) {
    if (!vehicleType || !extraInfos) {
      return false
    }

    const {
      enable_tally_and_transit_time: enableTallyAndTransitTime,
    } = extraInfos || {}

    // TODO: enable it back after API is ready
    const {
      tally_enabled: tallyEnabled,
    } = vehicleType.settings || {}

    return enableTallyAndTransitTime && tallyEnabled
  },

  extractTallyDataFromBookingAgainDetail(bookingAgainDetails = {}) {
    // Check subtotal
    const {
      original_transit_time: originalTransitTime,
      original_worst_transit_time: originalWorstTransitTime,
      original_eta_locations_id: originalEtaLocationsId,
      total_distance: totalDistance,
      subtotal,
      out_of_service_area_fee: outOfServiceAreaFee,
      is_out_of_service: isOutOfService,
      locations = [],
      ...rest
    } = bookingAgainDetails

    return {
      transit_time: originalTransitTime,
      worst_transit_time: originalWorstTransitTime,
      subtotal,
      total_distance: totalDistance,
      out_of_service_area_fee: outOfServiceAreaFee,
      is_out_of_service: isOutOfService,
      eta_locations_id: originalEtaLocationsId,
      locations_attributes: locations ? [...locations] : [],
      ...rest,
    }
  },
  // for now >= 0.1 km is round up to 1 km
  // but the requirement would be and specified at https://inspirelabs.atlassian.net/browse/DLVR-10523
  roundUp(value) {
    if (!_.isFinite(value)) {
      return value
    }

    // multiple by 10 to avoid 0.099999 when subtracting floating point number ex: 6.1 - 6
    const fraction = value * 10 - Math.floor(value) * 10
    if (fraction >= 1) {
      return Math.ceil(value)
    }

    return Math.floor(value)
  }
}

export { TallyUtils as default }

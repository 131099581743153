import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// COMPONENTS
import TallyBar from './TallyBar'
import CashbackInfo from './CashbackInfo'
import CashBackRenderItems from './CashBackRenderItems'
// UTILS
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'
import { LONG_HAUL } from 'constants/bookingConstants'
import { isEmpty } from 'lodash'

const mapStateToProps = state => ({
  extraInfos: state.extraInfos,
  currentCustomer: state.currentCustomer,
  locations: state.locations,
  booking: state.booking,
  bookAgainDetails: state.bookAgainDetails,
})

class CashBackBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showTally: this.hasTallyData(),
    }
    this.toggleTallyBar = this.toggleTallyBar.bind(this)
    this.handleSliderTallyBarEnd = this.handleSliderTallyBarEnd.bind(this)
  }

  sliderStyle() {
    const { needTransition } = this.state

    if (needTransition) {
      return 'slide-up'
    }

    return ''
  }

  hasTallyData() {
    const { tallyData } = this.props
    return tallyData.price !== null && tallyData.distance !== null
  }

  handleSliderTallyBarEnd() {
    const hasTallyData = this.hasTallyData()

    this.setState({ showTally: hasTallyData })
  }

  toggleTallyBar() {
    const hasTallyData = this.hasTallyData()

    if (hasTallyData) {
      this.setState({ showTally: true })
    }
  }

  render() {
    const {
      tallyData,
      extraInfos: {
        country_language: countryLanguage,
        country_code: countryCode,
        at_least_once_cash_back_reward: atLeastCashBack,
        currency,
      },
      tallyBarVisibility,
      isShopping,
      showTallybar,
      timeType,
      booking,
      currentStep,
      locations,
      isHaveLHAddress,
      extraInfos,
    } = this.props
    const { showTally } = this.state
    const displayPrice = Utils.formatFee(booking.cashBack?.cash_back_amount, countryLanguage, currency)

    const cashBackPercent = booking.cash_back_reward
    const cashbackBarCondition = !!(currentStep === 2 && booking.cashBack?.cash_back_amount > 0)
    const checkLocationName = locations[0]?.name
    const hideCashBackBar = (!!checkLocationName && !!timeType && !cashBackPercent)
    const priceText = (isHaveLHAddress || timeType === LONG_HAUL)
    ? extraInfos?.dynamic_texts?.service_notes_fpr?.text : ''
    const showCashBackStep1 = currentStep === 1 && (atLeastCashBack || priceText)

    return (
      <div className={`cashback-bar-wrapper ${cashbackBarCondition ? 'is-show' : ''}`}>
        <div
          className="cashback-bar-inner"
          onTransitionEnd={this.handleSliderTallyBarEnd}
        >
          {
            showCashBackStep1
            && <CashBackRenderItems
              cashBackPercent={cashBackPercent}
              hideCashBackBar={hideCashBackBar}
              priceText={priceText}
            />
          }

          {
            cashbackBarCondition && <CashbackInfo cashbackAmount={displayPrice} />
          }
          {showTallybar && (
            <div className="cashback-bar">
              <TallyBar
                price={tallyData.price}
                distance={tallyData.distance}
                transitTime={tallyData.transitTime}
                countryLanguage={countryLanguage}
                countryCode={countryCode}
                currency={currency}
                visibilitySetting={tallyBarVisibility}
                priceLabel={isShopping ? I18n.t('webapp.tally_bar.service_price') : null}
                toggleTallyBar={this.toggleTallyBar}
                showTallyData={showTally}
                waitingAnimation
                timeType={timeType}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

CashBackBar.propTypes = {
  tallyData: PropTypes.shape({}),
  booking: PropTypes.shape({}),
  locations: PropTypes.arrayOf(PropTypes.object),
  currentStep: PropTypes.number.isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  tallyBarVisibility: PropTypes.shape({}),
  isShopping: PropTypes.bool.isRequired,
  showTallybar: PropTypes.bool,
  timeType: PropTypes.string,
  isHaveLHAddress: PropTypes.bool,
}

CashBackBar.defaultProps = {
  tallyData: {},
  booking: {},
  locations: {},
  showTallybar: false,
  tallyBarVisibility: {},
  isHaveLHAddress: false,
}

export default connect(mapStateToProps)(CashBackBar)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IntlTelInput from 'react-intl-tel-input'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import _ from 'lodash'
import $ from 'jquery'
import { MIN_PHONE_INPUT_COUNT } from 'constants/bookingConstants'
// Utils
import phoneNumberUtils from 'utils/common/phoneNumber'
import I18n from 'i18n/i18n'
import classNames from 'classnames'

class InputPhoneNumber extends Component {
  static removeHighlight() {
    $(document).on('mouseleave', 'ul.country-list', () => {
      $('li.country.highlight').removeClass('highlight')
    })
    $(document).on('mouseenter', 'ul.country-list li.country', function aaa() {
      $(this).addClass('highlight')
    })
  }

  static scrollCountryListToTop() {
    $('.Input-Flag .intl-tel-input .country-list').animate({ scrollTop: 0 }, 'fast')
  }

  constructor(props) {
    super(props)
    const {
      countryCode,
      phoneNumber,
    } = props
    const excludeCountriesList = [
      'aw', 'al', 'as', 'ai', 'bm', 'io', 'vg', 'bq', 'ky', 'td', 'cx', 'cc', 'ck', 'cw', 'fk', 'fo', 'gf', 'pf', 'gi', 'gl', 'gp', 'gu', 'gg', 'im', 'je', 'mq', 'yt', 'ms', 'nc', 'nu', 'nf', 'mp', 'pg', 'pr', 're', 'bl', 'sh', 'mf', 'pm', 'sx', 'sj', 'tk', 'tc', 'vi', 'wf', 'eh', 'ax'
    ]
    const onlyCountriesList = [
      'id', 'ph', 'th', 'vn', 'au', 'be', 'br', 'ca', 'cn', 'fr', 'de', 'in', 'ir', 'ie', 'it', 'jp', 'nl', 'nz', 'no', 'pk', 'kr', 'ro', 'ru', 'sg', 'se', 'ch', 'ua', 'gb', 'us'
    ]
    let phoneCountryCode = countryCode
    let formattedNumber = phoneNumber
    if (!_.isEmpty(formattedNumber)) {
      formattedNumber = formattedNumber.replace(/^[0]+/, '')
      const parsedPhone = parsePhoneNumberFromString(formattedNumber)
      if (!_.isUndefined(parsedPhone) || !_.isEmpty(parsedPhone)) {
        const parsedPhoneCountryCode = parsedPhone.country
        if (!_.isUndefined(parsedPhoneCountryCode) && !_.isEmpty(parsedPhoneCountryCode)) {
          phoneCountryCode = _.toLower(parsedPhoneCountryCode)
        } else {
          phoneCountryCode = phoneNumberUtils.getCountryCodeByCallingCode(
            formattedNumber,
            phoneCountryCode
          )
        }
      }
    }
    if (!_.isUndefined(window.location)) {
      const isEditBooking = window.location.pathname.includes('edit')
      if (isEditBooking) {
        const result = phoneNumberUtils.formatPhoneNumber(formattedNumber, countryCode, true)
        formattedNumber = result.phoneNumber
      }
    }
    const countries = ['id', 'ph', 'th']
    this.state = {
      countryValue: phoneCountryCode,
      value: formattedNumber,
      excludeCountries: excludeCountriesList,
      onlyCountries: onlyCountriesList,
      listCountryActive: countries,
      selectCountry: {},
    }

    this.handleOnFlagClick = this.handleOnFlagClick.bind(this)
    this.onBlurCount = 0
    this.isLoadmore = false
    this.handleDebounce = _.debounce((number, countryData) => {
      this.handleBlurPhoneNumber(number, countryData)
    }, 500)
  }

  componentDidMount() {
    const { listCountryActive, onlyCountries, countryValue } = this.state
    const { closestElement } = this.props
    const onloaded = setInterval(() => {
      const listCountryElm = $(`.${closestElement}`).find('.country-list')
      if (listCountryElm.length > 0) {
        clearInterval(onloaded)

        phoneNumberUtils.initLoadedListCountry(closestElement, listCountryActive, onlyCountries)
        listCountryElm.append(`
          <li class='load-all-country-divider divider' />
          <div class='load-all-country divider mar5 w100 center underline small-font Green-text pb10 pt5'>
            ${I18n.t('webapp.label.all_countries')}
          </div>
        `)
        this.handleTopCountry(countryValue)
        InputPhoneNumber.setClassSelectedItem(countryValue)
      }
    }, 1000)

    $(document).on('click', `.${closestElement} .load-all-country`, () => {
      this.isLoadmore = true
      phoneNumberUtils.actionLoadmoreCountry(closestElement, listCountryActive)
    })
    InputPhoneNumber.removeHighlight()
  }

  componentWillReceiveProps(nextProps) {
    const { phoneNumber } = this.props
    const { countryValue } = this.state
    let countryCode = countryValue
    if (phoneNumber !== nextProps.phoneNumber) {
      const phone = _.isNull(nextProps.phoneNumber) ? '' : nextProps.phoneNumber
      if (!_.isEmpty(phone) && !_.isUndefined(phone)) {
        const result = phoneNumberUtils.formatPhoneNumber(phone, countryCode)
        const resultCountryCode = result.countryCode
        if (!_.isUndefined(resultCountryCode) && !_.isEmpty(resultCountryCode)) {
          countryCode = _.toLower(resultCountryCode)
        } else {
          countryCode = phoneNumberUtils.getCountryCodeByCallingCode(
            phone,
            countryCode
          )
        }
        this.setState({
          countryValue: countryCode,
          value: result.phoneNumber
        })
      }
      this.handleTopCountry(countryCode)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectCountry, value } = this.state
    const { onPhoneChange } = this.props
    if (
      !_.isUndefined(onPhoneChange)
      && (_.size(selectCountry) && selectCountry.iso2 !== prevState.selectCountry.iso2)
    ) {
      if (value.match(/^[0-9]+$/)) {
        onPhoneChange(`+${selectCountry.dialCode}${value}`)
      }
    }
  }

  static setClassSelectedItem(seletedCountryCode) {
    $('[data-selected-country="true"]').removeAttr('data-selected-country')
    $(`li.country[data-country-code=${seletedCountryCode}]:first`).attr('data-selected-country', 'true')
  }

  setPreferredCountries() {
    const { listCountryActive } = this.state
    const { countryCode } = this.props
    let countries = listCountryActive

    countries = _.orderBy(countries)
    countries.splice(0, 0, countryCode)
    countries = _.uniq(countries)
    return countries
  }

  handleChangePhoneNumber(number, fullNumber) {
    const {
      onPhoneChange,
      isWebApp,
      isContactInput,
      isBatchEz,
      isLocationInput
    } = this.props
    this.onBlurCount = 0
    let newNumberPhone = number.replace(/\s/g, '')
    if (!(isWebApp || isContactInput || isBatchEz || isLocationInput)) {
      const parsedPhone = parsePhoneNumberFromString(newNumberPhone)
      if (!_.isUndefined(parsedPhone) || !_.isEmpty(parsedPhone)) {
        const nationalNumber = parsedPhone.nationalNumber
        newNumberPhone = nationalNumber.replace(/^[0]+/, '')
      }
    }
    this.setState({ value: newNumberPhone })
    if (!(isWebApp || isContactInput || isBatchEz) && !_.isUndefined(onPhoneChange)) {
      let fullPhoneNumber = ''
      if (_.size(number) !== 0) {
        fullPhoneNumber = fullNumber.replace(/-|\s/g, '')
        fullPhoneNumber = fullPhoneNumber.replace(/^[0]+/, '')
      }
      onPhoneChange(fullPhoneNumber)
    }
  }

  handleBlurPhoneNumber(number, countryData) {
    const {
      onPhoneBlur,
      isWebApp,
      isContactInput,
      isBatchEz,
      isLocationInput
    } = this.props
    if (_.isUndefined(onPhoneBlur)) {
      return 
    }
    if (number.length <= MIN_PHONE_INPUT_COUNT) {
      onPhoneBlur('')
      return
    }

    this.onBlurCount += 1
    const { countryValue, value } = this.state
    let formattedNumber = number
    const regex = (isWebApp || isContactInput || isBatchEz || isLocationInput) ? /[^0-9+]+/g : /[^0-9]+/g
    formattedNumber = formattedNumber.replace(regex, '')
    formattedNumber = phoneNumberUtils.removeFirstCharacter(formattedNumber, countryData)
    if (!isContactInput) {
      this.setState({ value: formattedNumber })
    }

    // Get phoneNumber & countryCode when parsePhone
    const parsePhone = parsePhoneNumberFromString(formattedNumber, _.toUpper(countryData.iso2))
    let countryCodeValue = countryValue
    let phone = formattedNumber
    if (!_.isUndefined(parsePhone)) {
      if (!_.isUndefined(parsePhone.country) && !_.isEmpty(parsePhone.country)) {
        countryCodeValue = _.toLower(parsePhone.country)
      }
      phone = isContactInput ? parsePhone.nationalNumber : parsePhone.number
    }
    const hasPlus = phone.charAt(0) === '+'
    phone = phoneNumberUtils.addPlusToPhoneNumber(phone, hasPlus)
    if (isContactInput) {
      this.setState({ value: phone, countryValue: countryCodeValue })
    } else if ((isWebApp || isBatchEz || isLocationInput) && _.isUndefined(parsePhone)) {
      phone = phone.startsWith('+') ? phone.replace(/[^0-9]+/g, '') : phone
    }
    const result = phoneNumberUtils.checkTypingSameCurentPhone(
      phone,
      value,
      countryCodeValue
    )
    if (result.isSameCountryCode && !_.isEmpty(result.callingCode)) {
      phone = phoneNumberUtils.removeZeroAfterDialCode(phone, result.callingCode, hasPlus)
    }
    const isSamePhone = this.onBlurCount > 1 && (result.isSameInput || result.isSameCountryCode)
    const isSameInput = isSamePhone && !isContactInput
    if (isSameInput) {
      phone = phoneNumberUtils.removeCallingCodeWhenTypingSame(phone, countryCodeValue)
      this.setState({ value: phone })
    }
    if (!isSameInput) {
      onPhoneBlur(phone)
    }
  }

  handleFocusPhoneNumber(number, countryData) {
    const { onPhoneFocus } = this.props
    if (!_.isUndefined(onPhoneFocus) && number.length > MIN_PHONE_INPUT_COUNT) {
      let phone = parsePhoneNumberFromString(number, _.toUpper(countryData.iso2))
      phone = _.isUndefined(phone) ? number : phone.number
      onPhoneFocus(phone.replace(/\s/g, ''))
    }
  }

  handleCustomRef(refValue) {
    const { customRef } = this.props
    if (!_.isUndefined(customRef)) {
      customRef(refValue)
    }
  }

  updateCountryCode(country) {
    const { countryCodeField } = this.props

    let countryCode = country.iso2
    if (!_.isUndefined(countryCode) && !_.isEmpty(countryCode)) {
      countryCode = _.toLower(countryCode)
      this.setState({
        countryValue: countryCode,
        selectCountry: country,
      })
      if (countryCodeField) {
        $(`[name='${countryCodeField}']`).val(countryCode)
      }
      this.handleTopCountry(countryCode)
    }
  }

  handleOnFlagClick() {
    const { countryValue } = this.state
    InputPhoneNumber.scrollCountryListToTop()
    const { checkPhoneMask } = this.props
    if (!_.isUndefined(checkPhoneMask)) {
      clearTimeout(checkPhoneMask)
    }
    InputPhoneNumber.setClassSelectedItem(countryValue)
  }

  handleTopCountry(countryCode) {
    const { closestElement } = this.props
    const { listCountryActive, onlyCountries } = this.state
    let countries = listCountryActive
    countries = _.orderBy(countries)
    countries.splice(0, 0, countryCode)
    countries = _.uniq(countries)
    if (this.isLoadmore === false) {
      phoneNumberUtils.disabledListCountry(closestElement, listCountryActive, onlyCountries)
    }
    const closestElementTarget = $(`.${closestElement} ul.country-list`)
    if (closestElementTarget[0]) {
      let position = 0
      $(closestElementTarget[0]).find('li').css({ order: 'unset' })
      const length = countries.length
      _.each(countries, (country) => {
        const item = $(closestElementTarget[0]).find(`li[data-country-code="${country}"]`)[0]
        if (item) {
          position = -length + _.indexOf(countries, country)
          $(item).css({
            order: position,
            display: 'flex',
          })
        }
      })
    }
  }

  render() {
    const {
      phoneField, telInputProps, fieldId, autoFocus, closestElement, debounce, disabled, placeholder, isError
    } = this.props
    const {
      countryValue, value, excludeCountries
    } = this.state
    return (
      <div
        ref={(elm) => { this[`refHandler_${closestElement}`] = elm }}
        className = {classNames('Input Input-Flag', closestElement, { error : isError,  'Input-Disabled': disabled })}
      >
        <IntlTelInput
          autoFocus={autoFocus}
          ref={signUpPhone => this.handleCustomRef(signUpPhone)}
          fieldName={phoneField}
          fieldId={fieldId}
          telInputProps={telInputProps}
          value={value || ''}
          preferredCountries={this.setPreferredCountries()}
          containerClassName="intl-tel-input"
          inputClassName="form-control"
          autoPlaceholder={false}
          placeholder={placeholder !== undefined ? placeholder : I18n.t('webapp.booking.phone_number')}
          separateDialCode
          formatOnInit={false}
          defaultCountry={countryValue}
          excludeCountries={excludeCountries}
          onSelectFlag={(number, country) => this.updateCountryCode(country)}
          onFlagClick={this.handleOnFlagClick}
          onPhoneNumberChange={(isValid, number, countryData, fullNumber) => {
            this.handleChangePhoneNumber(number, fullNumber)
            if (debounce) {
              this.handleDebounce(number, countryData)
            }
          }}
          onPhoneNumberBlur={(isValid, number, countryData, fullNumber, id, event) => {
            this.handleBlurPhoneNumber(event.target.value, countryData)
          }}
          onPhoneNumberFocus={(isValid, number, countryData, fullNumber, id, event) => {
            this.handleFocusPhoneNumber(event.target.value, countryData)
          }}
        />
        <div className="flag-overlay-circle">
          <span className={`flag-icon flag-icon-${countryValue.toLowerCase()} flag-icon-squared`} />
        </div>
      </div>
    )
  }
}

InputPhoneNumber.propTypes = {
  isError: PropTypes.bool,
  phoneField: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  countryCode: PropTypes.string,
  countryCodeField: PropTypes.string,
  debounce: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string,
  phoneNumber: PropTypes.string,
  telInputProps: PropTypes.shape({}),
  customRef: PropTypes.func,
  onPhoneChange: PropTypes.func,
  onPhoneBlur: PropTypes.func,
  onPhoneFocus: PropTypes.func,
  checkPhoneMask: PropTypes.number,
  isWebApp: PropTypes.bool,
  isContactInput: PropTypes.bool,
  isBatchEz: PropTypes.bool,
  closestElement: PropTypes.string,
  editPhoneNumber: PropTypes.bool,
  isLocationInput: PropTypes.bool,
  placeholder: PropTypes.string
}

InputPhoneNumber.defaultProps = {
  isError: false,
  autoFocus: false,
  fieldId: '',
  countryCode: '',
  phoneNumber: '',
  countryCodeField: '',
  debounce: false,
  disabled: false,
  telInputProps: {},
  customRef: undefined,
  onPhoneChange: undefined,
  onPhoneBlur: undefined,
  onPhoneFocus: undefined,
  checkPhoneMask: undefined,
  isWebApp: false,
  isContactInput: false,
  isBatchEz: false,
  closestElement: 'input-phone',
  editPhoneNumber: false,
  isLocationInput: false,
  placeholder: undefined
}

export default InputPhoneNumber

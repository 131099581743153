import _ from 'lodash'
import { NODE_ENV } from 'constants/appConstants'
import { AF_TYPE_FULL_LOAD } from 'constants/trackingAppsFlyer'
import { cookie } from './cookie'
import store from 'store/store'

export const isCustomersIdIgnoredTracking = (customer_id = '') => {
  const ignoreCustomersIdArr = store.getState().extraInfos.ignore_applyer_for_these_customers;

  if(customer_id && ignoreCustomersIdArr?.includes(customer_id)) {
    return true
  }

  return false
}


export const appsFlyerTrackEvent = async (eventName, eventValue = {}) => {
  const is_logined = store.getState().currentCustomer.id ? true : false

  if(isCustomersIdIgnoredTracking(eventValue.customer_id ?? '')) return

  try {
    await window.AF('pba', 'event', { eventType: 'EVENT', eventValue: { web_version: 'v2', ..._.pickBy(eventValue), is_logined }, eventName })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export const appsFlyerSetCUID = async (currentCustomerId) => {
  if(isCustomersIdIgnoredTracking(currentCustomerId ?? '')) return

  try {
    await window.AF('pba', 'setCustomerUserId', `${currentCustomerId}`)
    await appsFlyerTrackEvent('SetCUID', {
      customer_user_id: currentCustomerId,
      af_user_id: cookie.getCookie('afUserId'),
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export const appsFlyerTurnOnDevMode = async () => {
  if (NODE_ENV !== 'production' && window?.AF_SDK) {
    try {
      await window.AF_SDK.PLUGINS.PBA.turnOnDevMode()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }
}

export const generateAppsFlyerEventObj = ({ extraInfos, currentServiceType, timeType, currentCustomer, booking, locations, currentVehicleType }) => {
  const commonFields = {
    service_area_id: extraInfos?.id,
    service_area: extraInfos?.name,
    service_type_id: currentServiceType?.id,
    service_type: currentServiceType?.name,
    load_type: AF_TYPE_FULL_LOAD,
    time_type: timeType,
    drop_count: locations?.length - 1,
    distance: booking?.total_distance,
    customer_type: currentCustomer?.customer_type,
    customer_id: currentCustomer?.id ?? '',
    company_id: currentCustomer?.last_login_employ_id,
    af_content_id: currentVehicleType?.id,
    af_content_type: currentVehicleType?.name, 
    af_country: extraInfos?.country_code,
    af_price: booking?.step1Price,
    af_currency: extraInfos?.currency,
  }

  return commonFields
}

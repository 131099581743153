import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

import * as googleMarkerActionCreators from 'store/actions/new_booking/googleMarkerActionCreators'

import { GMAPS_BROWSER_KEY, GMAPS_API_VERSION, MAP_STYLE, MAP_STYLE_URL } from 'constants/appConstants'
import { drawFulldayMegazoneAws } from 'utils/booking/common'
import { googleMapActionsCreator } from 'store/toolkit/googleMap/googleMap.reducer'
import { mapServiceActionsCreator } from 'store/toolkit/common/mapService.reducer'
import ShowRoute from 'components/common/ShowRoute'
import './styles.scss'
import mapUtils from 'utils/common/map'
import I18n from 'i18n/i18n'

const mapStateToProps = state => ({
  mapService: state.mapService,
  googleMap: state.googleMap,
  extraInfos: state.extraInfos,
  locations: state.locations,
  timeType: state.timeType,
  dropOffZones: state.dropOffZones,
  bookAgainDetails: state.bookAgainDetails,
})

function mapDispatchToProps(dispatch) {
  return {
    loadMapToStore: (data) => dispatch(googleMapActionsCreator.loadMapToStore(data)),
    setMapService: (data) => dispatch(mapServiceActionsCreator.setMapService(data)),
    googleMarkerActions: bindActionCreators(googleMarkerActionCreators, dispatch),
  }
}

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.map = null
  }

  componentDidMount() {
    const {
      extraInfos, zoom, latitude, longitude, setMapService, isEnableGoogleMap
    } = this.props
    const zoomNumber = extraInfos.default_map_zoom || zoom

    if(isEnableGoogleMap) {
      this.setupMapLoaded(zoomNumber)
      if (window.google && window.google.maps) {
        window.mapLoaded()
      } else {
        const s = document.createElement('script')
        s.src = `https://maps.googleapis.com/maps/api/js?key=${GMAPS_BROWSER_KEY}&v=${GMAPS_API_VERSION}&libraries=places,geometry&callback=mapLoaded&language=${I18n.language}&channel=7`
        document.head.appendChild(s)
      }
    } else {
      const latLng = extraInfos.default_center_lat_lng.split(',')
      const defaultCenter = latLng.length === 2 ? latLng.reverse() : [0, 0]
      this.map = new window.maplibregl.Map({
        container: 'map', // container id
        style: MAP_STYLE_URL, // style URL
        center: longitude && latitude ? [longitude, latitude] : defaultCenter, // starting position [lng, lat]
        zoom: zoomNumber // starting zoom
      });
      this.map.addControl(new window.maplibregl.FullscreenControl());
      this.map.addControl(new window.maplibregl.NavigationControl());
      this.map.on('load', function (res) {
        setMapService(res.target)
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      mapService, timeType, locations, extraInfos, fullLoadView, googleMap, isEnableGoogleMap
    } = this.props
    if (!_.isUndefined(locations[0]) && fullLoadView) {
      const isChangeMegaZone = Number(locations[0].lat) !== Number(nextProps.locations[0].lat)
        || Number(locations[0].lng) !== Number(nextProps.locations[0].lng)
      if (((isEnableGoogleMap && googleMap.map) || mapService) && (nextProps.timeType !== timeType || isChangeMegaZone)) {
        drawFulldayMegazoneAws({
          mapService, timeType: nextProps.timeType, extraInfos, locations: nextProps.locations,
          googleMap,
          megazone: this.mega,
          isEnableGoogleMap,
          callback: (newMegazone) => {
            this.mega = newMegazone
          }
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      mapService, extraInfos: currExtraInfos, zoom, fullLoadView, searchNearby,
      googleMap, isEnableGoogleMap,
    } = this.props
    const { extraInfos: prevExtraInfos } = prevProps

    if (!fullLoadView && this.mega) {
      if(isEnableGoogleMap) {
        this.mega.setMap(null)
      } else {
        mapUtils.removeLayerMegazone(mapService, this.mega)
      }
    }

    // if(googleMap.map && isEnableGoogleMap && prevProps.searchNearby !== searchNearby) {
    //   this.currentSearchNearby(googleMap.map, searchNearby)
    // }
    const isChangeLatLng = currExtraInfos.default_center_lat_lng !== prevExtraInfos.default_center_lat_lng
    const hasMap = (googleMap.map && isEnableGoogleMap) || mapService
    if (hasMap && isChangeLatLng) {
      const latLng = currExtraInfos.default_center_lat_lng.split(',')
      const newZoom = currExtraInfos.default_map_zoom || zoom
      if(isEnableGoogleMap) {
        googleMap.map.setCenter(new window.google.maps.LatLng(latLng[0], latLng[1]))
        googleMap.map.setZoom(newZoom)
      } else {
        mapService.setCenter([latLng[0], latLng[1]])
        mapService.setZoom(newZoom)
      }
    }
  }

  setupMapLoaded(zoomNumber) {
    const {
      extraInfos, bookAgainDetails, latitude, longitude, loadMapToStore
    } = this.props
    window.mapLoaded = () => {
      if (!this.map) return
      // add animation for marker
      require('marker-animate')
      const map = new window.google.maps.Map(this.map, {
        zoom: zoomNumber,
        center: new window.google.maps.LatLng(latitude, longitude),
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        streetViewControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
          style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR
        },
        zoomControl: true,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_TOP,
          style: window.google.maps.ZoomControlStyle.SMALL
        },
        scaleControl: true,
        scaleControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_BOTTOM
        },
        styles: MAP_STYLE
      })
      map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('show-route'))
      // Note: the fitBounds() happens asynchronously so setZoom() or setCenter() never work after fitBounds()
      // so you have to wait for a bounds_changed event before setting zoom or center works.
      window.google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
        if (extraInfos.default_center_lat_lng.length > 0 && _.isEmpty(bookAgainDetails)) {
          const addressDefault = JSON.parse(localStorage.getItem('addressDefault'))
          if (addressDefault) {
            const { lat, lng } = addressDefault.geometry.location
            map.setCenter(new window.google.maps.LatLng(lat, lng))
            window.localStorage.removeItem('addressDefault')
          } else {
            const latLng = extraInfos.default_center_lat_lng.split(',')
            map.setCenter(new window.google.maps.LatLng(latLng[0], latLng[1]))
          }
          map.setZoom(zoomNumber)
        }
      })


      const geocoder = new window.google.maps.Geocoder()
      const mapObject = {
        map,
        geocoder
      }
      loadMapToStore(mapObject)
    }
  }

  currentSearchNearby(map, searchNearby) {
    const {
      extraInfos,
      googleMarkerActions,
    } = this.props
    if (searchNearby) {
      const latLng = extraInfos.default_center_lat_lng.split(',')
      const location = new window.google.maps.LatLng(latLng[0], latLng[1])
      googleMarkerActions.renderNearbyMarker({
        location,
        radius: 2000,
        type: 'grocery_or_supermarket',
        key: GMAPS_BROWSER_KEY,
      }, map)
    } else {
      googleMarkerActions.removeNearbyMarker()
    }
  }

  render() {
    const { area, extraInfos, fullLoadView, mapService } = this.props
    return (
      <div className="Map-Overlay">
        {mapService && <ShowRoute area={area} extraInfos={extraInfos} fullLoadView={fullLoadView} />}
        <div className="Map" id="map" ref={(ref) => { this.map = ref }} style={{ position: 'relative', overflow: 'hidden' }} />
      </div>
    )
  }
}

Map.propTypes = {
  extraInfos: PropTypes.shape({ enable_full_day_megazone: PropTypes.bool }).isRequired,
  googleMarkerActions: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]).isRequired,
  googleMapActions: PropTypes.shape({}),
  googleMap: PropTypes.shape({}).isRequired,
  mapService: PropTypes.shape({}).isRequired,
  timeType: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({ lng: PropTypes.string, lat: PropTypes.string })).isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  zoom: PropTypes.number,
  bookAgainDetails: PropTypes.shape({}),
  searchNearby: PropTypes.bool,
  fullLoadView: PropTypes.bool,
  isEnableGoogleMap: PropTypes.bool
}

Map.defaultProps = {
  latitude: 0,
  longitude: 0,
  zoom: 12,
  bookAgainDetails: null,
  searchNearby: false,
  googleMapActions: {},
  fullLoadView: true,
  isEnableGoogleMap: true
}

export default connect(mapStateToProps, mapDispatchToProps)(Map)

import React from 'react'
import moment from 'moment'
import { get } from 'lodash'
import _ from 'lodash'
import $ from 'jquery'
// CONSTANTS
import { MONTH_NAMES_SHORT } from 'constants/dateConstants'
import {
  LTL_BOOKING_ICON,
  CANCELED_BOOKING_ICON,
  COMPLETED_BOOKING_ICON,
  NOW_BOOKING_ICON,
  SCHEDULE_BOOKING_ICON,
  FULL_DAY_BOOKING_ICON,
  LONG_HAUL_BOOKING_ICON,
  DRAFT_BOOKING_ICON,
  GOODS_ICON_CLOSURE,
  LCL_BOOKING_ICON,
} from 'constants/imageConstants'
// UTILS
import {
  BOOKING_FAILED_STATUS,
  FAILED_PAYMENT_STATUS,
  VALID_PAYMENT_LIST,
  STATUS_CANCELLED,
  STATUS_CS_FINDING_DRIVER,
  STATUS_DELIVERY_COMPLETED,
  STATUS_DELIVERY_IN_PROGRESS,
  STATUS_DRIVER_ACCEPT_BOOKING,
  LTL_DISABLED_BTN_CLASS,
  STATUS_TO_AT_PICKUP,
  STATUS_AT_PICKUP,
  STATUS_AT_DESTINATION,
  STATUS_GOING_TO_DESTINATION,
  STATUS_LOCATING_DRIVER,
  STATUS_LOCATING_DRIVER_TIMEOUT,
} from 'constants/bookingConstants'
import {
  POPUP_PRICE,
  POPUP_DELETE_SINGLE_BOOKING,
  BUTTON_CLOSURE_FAVORITE,
  BUTTON_BOOKMARK_LCL,
  WAITING_PAYMENT_BEFORE_EDIT_POPUP,
  BUTTON_BOOK_AGAIN_ERROR,
  CANCEL_BOOKING_MODAL,
} from 'constants/common/popupConstants'
import {
  BOOKING_TYPE,
  DRAFT,
  SCOPE_SORT_BY,
  TYPE_CONTAINER_LOAD,
  TYPE_PARTIAL_LOAD,
  TYPE_WHOLE_VEHICLE,
  UPCOMING,
} from 'constants/bookingCardsConstants'
import { isLocationFailedDelivery, isTrackingPage } from './common'
import { getDraftURL, handleSetClassDisablePTL } from '../new_booking/common'
import { LCL_CUSTOMER_URL } from '../../constants/appConstants'
import Utils from 'utils/Utils'
import I18n from 'i18n/i18n'
import { IMMEDIATE, NOW, STATUS_FLEET_ACCEPT_BOOKING } from 'constants/bookingConstants'

const ClosuresUtils = {
  getButtonsViewAsList(booking, currentCustomer) {
    const driver = get(booking, 'driver')
    const isLTL = get(booking, 'is_ltl')
    const itemsCount = get(booking, 'items_count', 0)
    const hasDriver = !_.isEmpty(driver) && !_.isNull(driver.id)
    const bookingCompletedOrCancelled = [STATUS_DELIVERY_COMPLETED, STATUS_CANCELLED].includes(booking.status)

    const isShowLiveTracking = _.includes(
      ['delivery_in_progress', 'driver_accept_booking', 'fleet_accept_booking', 'locating_driver', 'in_progress'],
      booking.status
    )

    const isShowEditFTL =
      !isLTL &&
      booking.status === 'driver_accept_booking' &&
      booking.ceb_enabled_feature &&
      currentCustomer.ceb_enabled_feature
    const isShowEditLTL =
      isLTL &&
      !bookingCompletedOrCancelled &&
      booking.status !== STATUS_CS_FINDING_DRIVER &&
      !hasDriver &&
      booking.quotes_count < 1

    const countryCodeOfBooking = booking?.area?.country_code?.toLowerCase()
    const areaId = booking?.area?.id

    const currentCompanyId = currentCustomer.current_company_id
    const idUser = currentCustomer.id
    const companyId = currentCompanyId || idUser
    const paramFTL = Utils.buildParamToFTL({
      countryCode: countryCodeOfBooking,
      areaId,
      companyId,
    })
    const ltlCustomerDomain = Utils.getLTLCustomerDomain(countryCodeOfBooking)
    const bookingDetailsUrl = `${ltlCustomerDomain}/listing-created/${get(booking, 'ltl_booking_id')}?${paramFTL}`
    const skipPhase2ToShowCancel =
      isLTL && !bookingCompletedOrCancelled && booking.status !== STATUS_DELIVERY_IN_PROGRESS

    const isShowReimburse =
      !isLTL && booking.company_id && booking.need_confirmation && booking.status === STATUS_DELIVERY_COMPLETED
    let linkToTracking = `/bookings/${booking.booking_code}/tracking`
    if (isLTL) {
      const isShowLivePTL = _.includes([STATUS_DRIVER_ACCEPT_BOOKING, STATUS_DELIVERY_IN_PROGRESS], booking.status)
      if (isShowLivePTL) {
        linkToTracking = `${ltlCustomerDomain}/tracking/${get(booking, 'ltl_booking_id')}?${paramFTL}`
      } else {
        linkToTracking = `${ltlCustomerDomain}/listing-created/${get(booking, 'ltl_booking_id')}?${paramFTL}`
      }
    }

    return [
      {
        bookingID: booking.id,
        classButton: handleSetClassDisablePTL('button-closure-addresses-modal', isLTL),
        iconName: 'place',
        isShow: true,
        title: isLTL ? I18n.t('closures.button.locations') : I18n.t('closures.label.address'),
        href: isLTL && `${bookingDetailsUrl}&tab=booking&scroll_to=locations`,
      },
      {
        bookingID: booking.id,
        classButton: handleSetClassDisablePTL(POPUP_PRICE, isLTL),
        iconName: 'attach_money',
        isShow: !isLTL || (isLTL && hasDriver),
        title: isLTL ? I18n.t('closures.label.payment') : I18n.t('closures.label.price'),
        href: isLTL && `${bookingDetailsUrl}&tab=payment`,
      },
      {
        bookingID: booking.id,
        classButton: handleSetClassDisablePTL('button-closure-note-modal', isLTL),
        iconName: 'description',
        isShow: true,
        title: isLTL ? I18n.t('closures.button.photos_doc') : I18n.t('closures.label.note'),
        href: isLTL && `${bookingDetailsUrl}&tab=booking&scroll_to=photos`,
      },
      {
        bookingID: booking.id,
        classButton: handleSetClassDisablePTL('button-closure-attachments-modal', isLTL),
        iconName: 'attachment',
        isShow: !isLTL,
        title: I18n.t('closures.label.attachment_popup_title'),
      },
      {
        classButton: handleSetClassDisablePTL('Book Again', isLTL),
        iconName: 'refresh',
        isShow: true,
        title: I18n.t('closures.label.boook_again'),
        href: isLTL
          ? `/?shipmentId=${booking.ltl_booking_id}&book_again=true&area_id=${areaId}`
          : `/bookings/${booking.id}/book_again?area_id=${areaId}`,
      },
      {
        bookingID: booking.id,
        classButton: handleSetClassDisablePTL(BUTTON_CLOSURE_FAVORITE, isLTL),
        classIcon: booking.marked_as_favorite ? 'yellow' : 'gray',
        iconName: 'star',
        isShow: true,
        title: I18n.t('closures.label.save'),
      },
      {
        bookingID: booking.id,
        classButton: handleSetClassDisablePTL('button-closure-signatures-modal', isLTL),
        iconName: 'gesture',
        isShow: !isLTL,
        title: I18n.t('closures.label.view_signature'),
      },
      {
        bookingID: booking.id,
        classButton: handleSetClassDisablePTL('button-closure-goods-modal', isLTL),
        srcImg: GOODS_ICON_CLOSURE,
        classImg: 'goods-icon',
        isShow: !isLTL,
        title: I18n.t('closures.label.goods'),
      },
      {
        bookingID: booking.id,
        classButton: handleSetClassDisablePTL('button-closure-docs-cod-modal', isLTL),
        iconName: 'assignment',
        isShow: !isLTL,
        title: I18n.t('closures.label.document_return_cod_title'),
      },
      {
        iconName: 'near_me',
        isShow: isShowLiveTracking,
        title: I18n.t('closures.label.go_to_live'),
        href: linkToTracking,
        classButton: LTL_DISABLED_BTN_CLASS,
      },
      {
        iconName: 'edit',
        isShow: isShowEditFTL || isShowEditLTL,
        title: I18n.t('closures.label.edit'),
        ltlBookingID: booking.ltl_booking_id,
        bookingAreaId: areaId,
        bookingCountryCode: countryCodeOfBooking,
        classButton: isShowEditLTL ? LTL_DISABLED_BTN_CLASS : '',
        href: isShowEditFTL ? `/bookings/${booking.booking_code}/edit?is_ceb=true` : '',
      },
      {
        isButtonReimburse: true,
        isShow: isShowReimburse,
      },
      {
        iconName: itemsCount <= 9 ? `filter_${itemsCount}` : 'filter_9_plus',
        isShow: isLTL,
        title: I18n.t('closures.button.items'),
        href: `${bookingDetailsUrl}&tab=booking&scroll_to=items`,
        classButton: LTL_DISABLED_BTN_CLASS,
      },
      {
        iconName: 'content_paste',
        isShow: isLTL,
        title: I18n.t('closures.button.summary'),
        href: `${bookingDetailsUrl}&tab=booking&scroll_to=summary`,
        classButton: LTL_DISABLED_BTN_CLASS,
      },
      {
        iconName: 'cancel',
        isShow: skipPhase2ToShowCancel,
        title: I18n.t('closures.label.cancel'),
        classButton: handleSetClassDisablePTL('', isLTL),
        ltlBookingID: booking.ltl_booking_id,
        driverId: hasDriver ? driver.id : '',
      },
    ]
  },
  getDraftButtonsViewAsList(booking) {
    return [
      {
        classButton: 'Book Again',
        iconName: 'refresh',
        isShow: true,
        title: I18n.t('closures.label.boook_again'),
        href: getDraftURL(booking.id),
      },
      {
        iconName: 'delete',
        isShow: true,
        title: I18n.t('webapp.action.delete'),
        classButton: POPUP_DELETE_SINGLE_BOOKING,
      },
    ]
  },
  getDriverPhone(booking) {
    let phone = ''
    switch (booking.status) {
      case 'delivery_in_progress':
      case 'driver_accept_booking':
        phone = booking.driver.phone
        break
      case 'fleet_accept_booking':
        phone = booking.fleet_partner.phone
        break
      case 'delivery_completed':
        if (booking.need_confirmation) {
          phone = booking.driver.phone
        }
        break
      default:
        phone = ''
    }
    return phone
  },
  getEditAndCancelButtonsInfo(booking, currentCustomer) {
    const buttonInfos = []
    const areaID = booking?.area?.id
    const countryCodeOfBooking = booking?.area?.country_code?.toLowerCase()
    // cheat to un-list to cancel
    const skipPhase2 = true
    if (booking.is_ltl) {
      if (booking.status !== STATUS_CS_FINDING_DRIVER && !booking.driver && booking.quotes_count < 1) {
        buttonInfos.push({
          href: '',
          name: I18n.t('closures.label.edit'),
          classButton: LTL_DISABLED_BTN_CLASS,
          ltlBookingID: booking.ltl_booking_id,
          bookingAreaId: areaID,
          bookingCountryCode: countryCodeOfBooking,
        })
      }
      if (!booking.driver && !skipPhase2) {
        buttonInfos.push({
          href: '',
          name: I18n.t('closures.label.unlist'),
          classButton: LTL_DISABLED_BTN_CLASS,
          ltlBookingID: booking.ltl_booking_id,
        })
      } else if (booking.status !== STATUS_DELIVERY_IN_PROGRESS) {
        buttonInfos.push({
          href: '',
          bookingID: booking.id,
          name: I18n.t('closures.label.cancel'),
          classButton: LTL_DISABLED_BTN_CLASS,
          ltlBookingID: booking.ltl_booking_id,
          driverId: booking.driver ? booking.driver.id : '',
        })
      }
    } else if (booking.status === 'driver_accept_booking') {
      if (booking.ceb_enabled_feature && currentCustomer.ceb_enabled_feature && _.isNumber(areaID)) {
        buttonInfos.push({
          name: I18n.t('closures.label.edit'),
          classButton: WAITING_PAYMENT_BEFORE_EDIT_POPUP,
        })
      }
      buttonInfos.push({
        href: `/bookings/${booking.booking_code}/tracking?is_cancel=true`,
        name: I18n.t('closures.label.cancel'),
      })
    } else if (booking.status === 'delivery_in_progress') {
      buttonInfos.push({
        href: '',
        name: I18n.t('closures.label.cancel'),
        classButton: CANCEL_BOOKING_MODAL,
      })
    }
    return buttonInfos
  },
  getFavoriteButtonsInfo(booking, currentViewAs, extraInfos) {
    const buttonInfos = []
    const areaId = booking?.area?.id || extraInfos?.area_id
    const param = Utils.buildParamToFTL({
      countryCode: 'id',
      areaId,
      companyId: null,
    })
    if (booking.is_ltl) {
      const buttonInfoLtl = {
        href: extraInfos?.ltl_enable_partial_load
          ? `/?shipmentId=${booking.ltl_booking_id}&book_again=true&area_id=${areaId}`
          : '',
        name: I18n.t('closures.label.boook_again'),
        classButton: BUTTON_BOOK_AGAIN_ERROR,
      }
      if (extraInfos?.ltl_enable_partial_load) delete buttonInfoLtl.classButton
      buttonInfos.push({ ...buttonInfoLtl, classButton: LTL_DISABLED_BTN_CLASS })
    } else if (booking.lcl_booking_id) {
      buttonInfos.push({
        href: `${LCL_CUSTOMER_URL}/booking/${booking.lcl_booking_id}?step=1&duplicate=true&${param}`,
        name: I18n.t('closures.label.boook_again'),
      })
    } else {
      buttonInfos.push({
        href: `/bookings/${booking.booking_code}/book_again?area_id=${areaId}`,
        name: I18n.t('closures.label.boook_again'),
      })
    }
    let favBtnClass = BUTTON_CLOSURE_FAVORITE
    if (booking.lcl_booking_id) favBtnClass = BUTTON_BOOKMARK_LCL
    if (booking.is_ltl) favBtnClass = `${BUTTON_CLOSURE_FAVORITE} ${LTL_DISABLED_BTN_CLASS}`
    buttonInfos.push({
      bookingID: booking.lcl_booking_id ? booking.lcl_booking_id : booking.id,
      classButton: favBtnClass,
      name: booking.marked_as_favorite ? I18n.t('closures.label.unsave') : I18n.t('closures.label.save'),
      viewAsData: currentViewAs,
      isLtl: booking.is_ltl,
      isFavorite: booking.marked_as_favorite,
    })
    return buttonInfos
  },
  getFirstRowBookingDetails(booking, scope) {
    const isHasDeclined = this.getIsBookingHasDeclined(booking)
    return [
      {
        isShow: true,
        buttons: [
          {
            bookingID: booking.id,
            classButton: POPUP_PRICE,
            name: I18n.t('closures.label.price'),
          },
          {
            bookingID: booking.id,
            classButton: 'button-closure-addresses-modal',
            name: (
              <span className={`${!!isHasDeclined && 'declined-red-dot'}`}>{I18n.t('closures.label.address')}</span>
            ),
          },
          {
            bookingID: booking.id,
            classButton: 'button-closure-signatures-modal',
            href: '',
            name: I18n.t('closures.label.signature'),
          },
        ],
      },
    ]
  },
  getFirstRowLtlBookingDetails(booking, currentCustomer) {
    const countryCodeOfBooking = booking?.area?.country_code?.toLowerCase()
    const areaId = booking?.area?.id
    // const isShowQuote = !hasDriver && booking.status !== STATUS_CANCELLED
    const currentCompanyId = currentCustomer.current_company_id
    const idUser = currentCustomer.id
    const companyId = currentCompanyId || idUser
    const paramFTL = Utils.buildParamToFTL({
      countryCode: countryCodeOfBooking,
      areaId,
      companyId,
    })
    const bookingDetailsUrl = `${Utils.getLTLCustomerDomain(countryCodeOfBooking)}/listing-created/${get(
      booking,
      'ltl_booking_id'
    )}?${paramFTL}`
    return [
      {
        isShow: true,
        buttons: [
          {
            href: `${bookingDetailsUrl}&tab=booking&scroll_to=summary`,
            name: I18n.t('closures.button.summary'),
            classButton: LTL_DISABLED_BTN_CLASS
          },
          {
            href: `${bookingDetailsUrl}&tab=booking&scroll_to=locations`,
            name: I18n.t('closures.button.locations'),
            classButton: LTL_DISABLED_BTN_CLASS
          },
        ],
      },
    ]
  },
  handlePickupTimeFTL(booking) {
    const countryCode = booking.country_code
    const timeType = booking.time_type
    const pickupTime = Utils.formatDateTime(booking.pickup_time * 1000, countryCode)
    if (timeType === NOW || timeType === IMMEDIATE) {
      return I18n.t('webapp.booking.asap')
    }
    return `${pickupTime.time}, ${pickupTime.date}`
  },
  handlePickupTimeLTL(booking, extraInfos) {
    const countryCode = booking.country_code
    const hasDriver = !_.isEmpty(booking.driver) && !_.isNull(booking.driver?.id)
    const { ltl_booking_start_time: startTime, ltl_booking_end_time: endTime } = extraInfos

    return booking.pickup_time_for_same_day
      ? Utils.renderCalendarNotify({
          pickupTimeLTL: hasDriver ? moment(booking.pickup_time * 1000) : booking.pickup_time_for_same_day,
          extraInfos,
          countryCode,
          isMyBooking: true,
          hasDriver,
        })
      : Utils.pickupTimeLTL(startTime, endTime, booking.pickup_time * 1000, countryCode)
  },
  handlePickupTimeFTLAndLTL(booking, extraInfos, rowBooking = false) {
    const countryCode = booking.country_code
    const timeType = booking.time_type
    const bookingImmediate = timeType === NOW || timeType === IMMEDIATE
    const isLTL = get(booking, 'is_ltl')
    const pickupAt = get(booking.locations?.[0], 'signature_updated_at')

    const pickupTimeMarketLTL = isLTL && this.handlePickupTimeLTL(booking, extraInfos)
    const pickupTimeMarketFTL = this.handlePickupTimeFTL(booking)
    const pickupTimeMarket = isLTL ? pickupTimeMarketLTL : pickupTimeMarketFTL
    const pickupCompleted = Utils.formatDateTime(pickupAt * 1000, countryCode)
    const pickupTime = pickupAt ? `${pickupCompleted.time}, ${pickupCompleted.date}` : pickupTimeMarket

    if (rowBooking) return pickupTime

    if (!isLTL) {
      if (bookingImmediate) {
        const labelPickupImmediate = pickupAt
          ? I18n.t('webapp.booking.pick_up_actual')
          : I18n.t('webapp.booking.pick_up_at')
        return [{ isShow: true, label: labelPickupImmediate, value: pickupTime }]
      }
      return [
        { isShow: true, label: I18n.t('webapp.booking.requested_by'), value: pickupTimeMarketFTL },
        {
          isShow: !!pickupAt,
          label: booking.locations?.[0]?.signature_updated_at
            ? I18n.t('webapp.booking.pick_up_actual')
            : I18n.t('webapp.booking.pick_up_at'),
          value: booking.locations?.[0]?.signature_updated_at
            ? pickupTime
            : I18n.t('webapp.booking.asap')
        },
      ]
    }
    return [{ isShow: true, label: I18n.t('webapp.booking.pick_up'), value: pickupTime }]
  },
  getFirstSectionCardRowInfos(booking, extraInfos) {
    const isLTL = get(booking, 'is_ltl')
    const results = this.handlePickupTimeFTLAndLTL(booking, extraInfos)
    return [
      {
        isShow: !(isLTL && !booking.driver),
        label: I18n.t('closures.label.vehicle'),
        value: booking.vehicle_type?.name,
      },
      // { isShow: !isLTL, label: I18n.t('closures.label.request'), value: `${createdAt.time}, ${createdAt.date}` },
      ...results,
    ]
  },
  getRatingStars(booking, bookingFleet) {
    const options = {
      starClass: 'b yellow material-icons Icon',
      starText: 'star',
      starHalfClass: 'b yellow material-icons Icon',
      starHalfText: 'star_half',
      starEmptyClass: 'b gray material-icons Icon',
      starEmptyText: 'star',
    }
    let rating = (bookingFleet ? booking.fleet_partner.rating : booking.driver.rating) || 0
    let emptyStar = _.floor(5 - rating)
    const arrStars = []
    while (rating > 0) {
      if (rating >= 1) {
        arrStars.push({ classIcon: options.starClass, label: options.starText })
      } else {
        arrStars.push({ classIcon: options.starHalfClass, label: options.starHalfText })
      }
      rating -= 1
    }
    while (emptyStar > 0) {
      arrStars.push({ classIcon: options.starEmptyClass, label: options.starEmptyText })
      emptyStar -= 1
    }
    return arrStars
  },
  getSecondRowBookingDetails(booking) {
    return [
      {
        isShow: true,
        buttons: [
          {
            bookingID: booking.id,
            classButton: 'button-closure-note-modal',
            name: I18n.t('closures.label.note'),
          },
          {
            bookingID: booking.id,
            classButton: 'button-closure-attachments-modal',
            name: I18n.t('closures.label.attachment'),
          },
          {
            bookingID: booking.id,
            classButton: 'button-closure-docs-cod-modal',
            name: I18n.t('closures.label.document_return_cod'),
          },
        ],
      },
    ]
  },
  getSecondRowLtlBookingDetails(booking, currentCustomer) {
    const driver = get(booking, 'driver')
    const hasDriver = !_.isEmpty(driver) && !_.isNull(driver.id)
    const isShowPayment = booking.status !== STATUS_CANCELLED
    const countryCodeOfBooking = booking?.area?.country_code?.toLowerCase()
    const areaId = booking?.area?.id

    const currentCompanyId = currentCustomer.current_company_id
    const idUser = currentCustomer.id
    const companyId = currentCompanyId || idUser
    const paramFTL = Utils.buildParamToFTL({
      countryCode: countryCodeOfBooking,
      areaId,
      companyId,
    })

    const bookingDetailsUrl = `${Utils.getLTLCustomerDomain(countryCodeOfBooking)}/listing-created/${get(
      booking,
      'ltl_booking_id'
    )}?${paramFTL}`
    const buttonRowInfos = [
      {
        isShow: true,
        buttons: [
          {
            href: `${bookingDetailsUrl}&tab=booking&scroll_to=photos`,
            name: I18n.t('closures.button.photos_doc'),
            classButton: LTL_DISABLED_BTN_CLASS
          },
          {
            href: `${bookingDetailsUrl}&tab=booking&scroll_to=items`,
            name: I18n.t('closures.button.items'),
            classButton: LTL_DISABLED_BTN_CLASS
          },
          {
            href: `${bookingDetailsUrl}&tab=payment`,
            isShow: isShowPayment && hasDriver,
            name: I18n.t('closures.button.payment'),
            classButton: LTL_DISABLED_BTN_CLASS
          },
        ],
      },
    ]
    return buttonRowInfos
  },
  getIsBookingHasDeclined(booking) {
    return booking.locations?.reduce((c, i) => (isLocationFailedDelivery(i?.status) ? c + 1 : c), 0)
  },
  getSecondSectionCardRowInfos(booking) {
    const language = booking.area_language || 'en'
    const isFullDayBooking = booking.time_type === 'full_day'
    const completedAt = booking.completed_at
    const countryCode = booking.country_code
    const formatCompletedAt = (completedAt && Utils.formatDateTime(completedAt * 1000, countryCode)) || undefined
    const validCompletedAt = (formatCompletedAt && `${formatCompletedAt.time}, ${formatCompletedAt.date}`) || '-'
    const totalDistance = Utils.formatDistance(booking.total_distance, language)
    const displayDistance = isFullDayBooking ? '-' : I18n.t('closures.label.distance_val', { distance: totalDistance })
    const destination = isFullDayBooking || _.isEmpty(booking.locations) ? '-' : booking.locations.length - 1
    const declinedCount = this.getIsBookingHasDeclined(booking)
    const destinationDistanceText = `${destination}${
      declinedCount ? ` (${I18n.t('closures.label.count_declined', { count: declinedCount })})` : ''
    }/${displayDistance}`
    const displayDestinationDistance = (
      <span className={`${!!declinedCount && 'declined-red-dot'}`}>{destinationDistanceText}</span>
    )
    const displayPrice = Utils.formatFee(booking.display_total_fees, language, booking.currency, '-')
    const paymentType = _.isEmpty(booking.payment_type) ? '-' : booking.payment_type
    const displayPaymentType = paymentType
    const displayJobOderNumber = _.isEmpty(booking.job_order_number) ? '-' : booking.job_order_number
    return [
      { isShow: true, label: I18n.t('closures.label.complete'), value: validCompletedAt },
      { isShow: true, label: I18n.t('closures.label.destination_distance'), value: displayDestinationDistance },
      { isShow: true, label: I18n.t('closures.label.price'), value: displayPrice },
      { isShow: true, label: I18n.t('closures.label.payment'), value: displayPaymentType },
      { isShow: true, label: I18n.t('closures.label.job_order_number'), value: displayJobOderNumber },
    ]
  },
  getSectionCardDraftRowInfos(booking) {
    const countryCode = booking?.area?.country_code || 'id'
    const createdAt = Utils.formatDateTime(booking.created_at * 1000, countryCode)
    let displayPickupTime = '-'
    let displayDestination = '-'
    const displayJobOderNumber = _.isEmpty(booking.job_order_number) ? '-' : booking.job_order_number
    if (_.isNumber(booking.pickup_time)) {
      const pickupTime = Utils.formatDateTime(booking.pickup_time * 1000, countryCode)
      displayPickupTime = `${pickupTime.time}, ${pickupTime.date}`
    }
    if (!_.isEmpty(booking.locations)) {
      displayDestination = booking.locations.length - 1
    }
    return [
      { isShow: true, label: I18n.t('closures.label.created'), value: `${createdAt.time}, ${createdAt.date}` },
      { isShow: true, label: I18n.t('webapp.booking.pick_up'), value: displayPickupTime },
      { isShow: true, label: I18n.t('closures.label.complete'), value: '-' },
      { isShow: true, label: I18n.t('closures.label.distance'), value: '-' },
      { isShow: true, label: I18n.t('closures.label.distination'), value: displayDestination },
      { isShow: true, label: I18n.t('closures.label.price'), value: '-' },
      { isShow: true, label: I18n.t('closures.label.payment'), value: '-' },
      { isShow: true, label: I18n.t('closures.label.job_order_number'), value: displayJobOderNumber },
    ]
  },
  getTallyEarlyInfo(currentEstimateValues, originalEstimateValues, isPTL) {
    const timeDiffToSeconds = Math.abs(
      moment(originalEstimateValues[0]).diff(moment(currentEstimateValues[0]), 'seconds')
    )
    return [
      {
        hasSpan: true,
        isShow: true,
        label: I18n.t(`webapp.tally.${isPTL ? 'reach_delivery_by' : 'reach_final_stop_by'}`),
        spanClass: 'transit-time-status green',
        value: I18n.t('webapp.tally.early_by_time', { time: Utils.convertSecondsToTimeString(timeDiffToSeconds) }),
      },
      {
        isShow: true,
        label: I18n.t('webapp.tally.original_estimate'),
        value: Utils.formatTallyDatetime(originalEstimateValues),
      },
      {
        isShow: true,
        label: I18n.t('webapp.tally.current_estimate'),
        value: Utils.formatTallyDatetime(currentEstimateValues),
      },
    ]
  },
  tallyInFrontPage(booking) {
    let timeReachFinalStopBy
    switch (booking.status) {
      case STATUS_FLEET_ACCEPT_BOOKING:
      case STATUS_AT_PICKUP:
      case STATUS_TO_AT_PICKUP:
      case STATUS_DRIVER_ACCEPT_BOOKING:
        timeReachFinalStopBy = Utils.formatTallyDatetime(booking?.tally_info?.original_estimate_values)
        break
      case STATUS_DELIVERY_IN_PROGRESS:
      case STATUS_AT_DESTINATION:
      case STATUS_GOING_TO_DESTINATION:
        timeReachFinalStopBy = Utils.formatTallyDatetime(booking?.tally_info?.current_estimate_values)
        break
      default:
        break
    }
    if (booking?.tally_info?.last_drop_off_arrived_at)
      timeReachFinalStopBy = Utils.formatTallyDatetime([booking?.tally_info?.last_drop_off_arrived_at])
    return (
      <div className="flex booking-card__status mb10">
        {!booking?.tally_info?.last_drop_off_arrived_at
          ? I18n.t(`webapp.tally.reach_final_stop_by`)
          : I18n.t('webapp.tally.arrival_time')}
        :
        <span className="ml5" title={timeReachFinalStopBy}>
          {timeReachFinalStopBy}
        </span>
      </div>
    )
  },
  getTallyLateInfo(currentEstimateValues, originalEstimateValues, isPTL) {
    const timeDiffToSeconds = Math.abs(
      moment(originalEstimateValues[0]).diff(moment(currentEstimateValues[0]), 'seconds')
    )
    return [
      {
        hasSpan: true,
        isShow: true,
        label: I18n.t(`webapp.tally.${isPTL ? 'reach_delivery_by' : 'reach_final_stop_by'}`),
        spanClass: 'transit-time-status red',
        value: I18n.t('webapp.tally.time_late', { time: Utils.convertSecondsToTimeString(timeDiffToSeconds) }),
      },
      {
        isShow: true,
        label: I18n.t('webapp.tally.original_estimate'),
        value: Utils.formatTallyDatetime(originalEstimateValues),
      },
      {
        isShow: true,
        label: I18n.t('webapp.tally.current_estimate'),
        value: Utils.formatTallyDatetime(currentEstimateValues),
      },
    ]
  },
  getTallyNowInfo(lastDropOffArrivedAt, originalEstimateValues, isPTL) {
    return [
      {
        hasSpan: true,
        isShow: true,
        label: I18n.t(`webapp.tally.${isPTL ? 'at_delivery' : 'at_final_stop'}`),
        spanClass: 'transit-time-status',
        value: I18n.t('webapp.tally.now'),
      },
      {
        isShow: true,
        label: I18n.t('webapp.tally.original_estimate'),
        value: Utils.formatTallyDatetime(originalEstimateValues),
      },
      {
        isShow: true,
        label: I18n.t('webapp.tally.arrival_time'),
        value: Utils.formatTallyDatetime([lastDropOffArrivedAt]),
      },
    ]
  },
  getTallyOnScheduleInfo(originalEstimateValues, isPTL) {
    return [
      {
        hasSpan: true,
        isShow: true,
        label: I18n.t(`webapp.tally.${isPTL ? 'reach_delivery_by' : 'reach_final_stop_by'}`),
        spanClass: 'transit-time-status green',
        value: I18n.t('webapp.tally.on_schedule'),
      },
      {
        isShow: true,
        label: I18n.t('webapp.tally.original_estimate'),
        value: Utils.formatTallyDatetime(originalEstimateValues),
      },
    ]
  },
  getTallyWaitingForPickupInfo(originalEstimateValues, isPTL) {
    return [
      {
        hasSpan: true,
        isShow: true,
        label: I18n.t('webapp.tally.status'),
        spanClass: 'transit-time-status',
        value: I18n.t('webapp.tally.waiting_for_pickup'),
      },
      {
        isShow: true,
        label: I18n.t(`webapp.tally.${isPTL ? 'reach_delivery_by' : 'reach_final_stop_by'}`),
        value: Utils.formatTallyDatetime(originalEstimateValues),
      },
    ]
  },
  getThirdRowBookingDetails(booking, scope) {
    const bookingDetailsPath = `/bookings/${booking.id}?scope=${scope}`
    return [
      {
        isShow: true,
        buttons: [
          {
            bookingID: booking.id,
            classButton: 'button-closure-driver-note-modal',
            isShow: true,
            name: I18n.t('closures.label.driver_note'),
          },
          { bookingID: booking.id, classButton: 'button-closure-goods-modal', name: I18n.t('closures.label.goods') },
          { href: bookingDetailsPath, name: I18n.t('webapp.action.receipt') },
        ],
      },
    ]
  },
  getStatusTextPayment(payment, bookingStatus) {
    const paymentStatus = payment?.status
    if (!_.isEmpty(payment) && !isTrackingPage()) {
      if (VALID_PAYMENT_LIST.includes(paymentStatus) && !BOOKING_FAILED_STATUS.includes(bookingStatus)) return I18n.t('closures.status.waiting_payment')

      if ((FAILED_PAYMENT_STATUS.includes(payment.status))) return I18n.t('closures.status.expired_payment')
    }
    return ''
  },
  getStatusTextComplete(booking) {
    const {
      need_confirmation: needConfirmation, is_ltl: isPTL,
      waiting_for_customer_confirmation: waitingForCustomerConfirmation, total_fees: totalFees,
      company_id: companyID, reimbursement_status: reimbursementStatus, chat_enable: chatEnable,
    } = booking
    // Case BP and Non-BP full credit booking
    if ((!companyID && (totalFees === 0)) || companyID) {
      if (reimbursementStatus === 'auto_confirmed') {
        return I18n.t('closures.status.completed')
      }
      return I18n.t(`closures.status.${(needConfirmation || waitingForCustomerConfirmation) && !isPTL ? 'reimburse' : 'completed'}`)
    }
    // Case Non-BP NOT full credit booking
    if (!companyID && totalFees > 0) {
      if ((reimbursementStatus === 'driver_submitted_claim' && !(needConfirmation || waitingForCustomerConfirmation) && chatEnable && !isPTL)
        || ((needConfirmation || waitingForCustomerConfirmation) && !isPTL)) {
        return I18n.t('closures.status.reimburse')
      }
      return I18n.t('closures.status.completed')
    }
    return I18n.t(`closures.status.${(needConfirmation || waitingForCustomerConfirmation) && !isPTL ? 'reimburse' : 'completed'}`)
  },
  getStatusTextInprogress(locations) {
    const arrLocations = locations || []
    if (arrLocations.length > 2) {
      for (let i = 1; i < arrLocations.length; i += 1) {
        if (_.isNull(arrLocations[i].signature_updated_at)) {
          if (arrLocations[i].arrived_at) {
            return `${I18n.t('closures.status.at_destination')}${` ${i}`}`
          }
          return `${I18n.t('closures.status.going_to_destination')}${` ${i}`}`
        }
      }
    } else if (arrLocations[1]?.arrived_at) {
      return I18n.t('closures.status.at_destination')
    }
    return I18n.t('closures.status.going_to_destination')
  },
  // isApplyPayment temporary apply for booking detail, booking card.
  // Will remove isApplyPayment and improve when apply for any where in project.
  showBookingStatusText(booking, isShowDetailCancelStatus = false, payment) {
    const defaultLabel = I18n.t('closures.status.canceled')
    const { time_type: timeType, vehicle_type: vehicleType } = booking

    const periodTime = !_.isEmpty(vehicleType)
      ? (_.toFinite(vehicleType.settings.sending_eta_sms_period) * 3600 * 1000) : null

    const atPickup = get(booking, 'locations[0].arrived_at')

    const textStatusPayment = this.getStatusTextPayment(payment, booking?.status)
    if (textStatusPayment) return textStatusPayment

    switch (booking.status) {
      case 'assigning_driver':
      case 'locating_driver':
      case 'waiting_approval':
      case 'approved':
      case 'recovering':
      case 'locating_driver_timeout':
      case 'cs_finding_driver':
        return I18n.t('closures.status.locating_driver')
      case 'driver_accept_booking':
      case 'fleet_accept_booking':
      case 'fleet_timeout':
      case 'booked':
        if (timeType === 'now') {
          return I18n.t('closures.status.driver_on_the_way')
        }
        if (timeType === 'full_day') {
          return I18n.t('closures.status.fullday_confirm')
        }
        if (atPickup) {
          return I18n.t('closures.status.at_pickup')
        }
        if (periodTime && (periodTime + _.toInteger(moment()) > booking.pickup_time * 1000)) {
          return I18n.t('closures.status.driver_on_the_way')
        }
        return I18n.t('closures.status.schedule_confirm')
      case 'delivery_in_progress':
      case 'in_progress':
        return this.getStatusTextInprogress(booking.locations)
      case 'delivery_completed':
      case 'completed':
        return this.getStatusTextComplete(booking)
      case 'un_listed':
      case 'canceled':
      case 'cancel_to_edit':
        if (booking.canceled_by_customer && isShowDetailCancelStatus) {
          return I18n.t('closures.status.you_canceled')
        }
        if (booking.canceled_by_driver && isShowDetailCancelStatus) {
          return I18n.t('closures.status.driver_canceled')
        }
        return defaultLabel
      default:
        return defaultLabel
    }
  },
  showTestBookingActionStatusText(status) {
    switch (status) {
      case STATUS_LOCATING_DRIVER:
      case STATUS_LOCATING_DRIVER_TIMEOUT:
      case STATUS_CS_FINDING_DRIVER:
        return 'Driver Accepts Booking'
      case STATUS_DRIVER_ACCEPT_BOOKING:
        return 'Customer Confirms and Signs at Pickup Location'
      case STATUS_DELIVERY_IN_PROGRESS:
        return 'Customer Confirms and Signs at Last Destination'
      default:
        return ''
    }
  },
  showBookingIcon(status, timeType, isLtl, needConfirmation, waitingForCustomerConfirmation, LCLStatus) {
    const bookingStatus = LCLStatus || status
    const arrCanceled = [
      'cancelled',
      'assigning_driver_timeout',
      'canceled',
      'canceled_rebook',
      'driver_declined_booking',
      'un_listed',
    ]
    if (
      !timeType &&
      LCLStatus &&
      !_.includes(arrCanceled, bookingStatus) &&
      !_.includes(['delivery_completed', 'completed'], bookingStatus)
    )
      return LCL_BOOKING_ICON
    if (_.includes(arrCanceled, bookingStatus)) {
      return CANCELED_BOOKING_ICON
    }
    if (
      _.includes(['delivery_completed', 'completed'], bookingStatus) &&
      !needConfirmation &&
      !waitingForCustomerConfirmation
    ) {
      return COMPLETED_BOOKING_ICON
    }
    if (isLtl) {
      return LTL_BOOKING_ICON
    }
    switch (timeType) {
      case 'now':
        return NOW_BOOKING_ICON
      case 'schedule':
        return SCHEDULE_BOOKING_ICON
      case 'full_day':
        return FULL_DAY_BOOKING_ICON
      case 'long_haul':
        return LONG_HAUL_BOOKING_ICON
      default:
        return DRAFT_BOOKING_ICON
    }
  },

  showBookingStatusIcon(status) {
    switch (status) {
      case 'locating_driver':
      case 'locating_driver_timeout':
      case 'cs_finding_driver':
      case 'fleet_timeout':
      case 'assigning_driver':
        return 'wifi_tethering'
      case 'delivery_in_progress':
        return 'cached'
      case 'delivery_completed':
        return 'done_all'
      case 'canceled':
      case 'assigning_driver_timeout':
      case 'driver_declined_booking':
        return 'pan_tool'
      default:
        return ''
    }
  },
  showBookingStatusIconColor(status) {
    switch (status) {
      case 'delivery_completed':
      case 'delivery_in_progress':
      case 'driver_accept_booking':
      case 'fleet_accept_booking':
        return 'green'
      case 'assigning_driver_timeout':
      case 'canceled':
      case 'driver_declined_booking':
        return 'violet'
      default:
        return ''
    }
  },
  timeTypeClassCard(timeType) {
    switch (timeType) {
      case 'now':
        return ''
      case 'schedule':
        return 'Closure-Scheduled'
      case 'full_day':
        return 'Closure-Fullday'
      case 'long_haul':
        return 'Closure-Longhaul'
      default:
        return 'Closure-Draft'
    }
  },
  convertMonthLocaleToEn(dateString) {
    const lang = I18n.language
    if (!dateString) {
      return ''
    }
    if (lang === 'id' || lang === 'th') {
      const monthNameLocale = dateString.split('-')[1]
      const index = MONTH_NAMES_SHORT[lang].findIndex((month) => month === monthNameLocale)
      if (index !== -1) {
        return dateString.replace(monthNameLocale, MONTH_NAMES_SHORT.en[index])
      }
    }
    return dateString
  },
  paramToGetBooking(currentPage, scope, currentCustomer) {
    const fromDateInput = $('#from_date_input') ? $('#from_date_input').val() : ''
    const toDateInput = $('#to_date_input') ? $('#to_date_input').val() : ''
    const fromTime = ClosuresUtils.convertMonthLocaleToEn(fromDateInput)
    const toTime = ClosuresUtils.convertMonthLocaleToEn(toDateInput)
    const searchOption = $('#search_option') ? $('#search_option').val() : ''
    const searchFuzzySearch = $('#search_fuzzy_search') ? $('#search_fuzzy_search').val() : ''
    const params = {
      page: currentPage,
      from_time: fromTime,
      to_time: toTime,
    }

    if (scope === DRAFT.scope) {
      if (currentCustomer.current_company_id) {
        params.company_id = currentCustomer.current_company_id
      }
      return params
    }
    if (currentCustomer.current_company_id) {
      params['company_ids'] = [currentCustomer.current_company_id]
      return {
        ...params,
        scope,
        ...(searchOption ? { filter_option: searchOption } : {}),
        ...(searchFuzzySearch ? { filter_key: searchFuzzySearch } : {}),
        sort_by: SCOPE_SORT_BY[scope],
        sort_dir: scope === UPCOMING.scope ? 'asc' : 'desc',
      }
    }
  },
  paramToGetBookingSingle(paramToGetBooking) {
    const {
      fromDateTimeSearch, toDateTimeSearch, searchFuzzySearch, currentPage, 
      currentCustomer, searchOption, typeBooking, scope
     } = paramToGetBooking
    const fromTime = fromDateTimeSearch ? moment(fromDateTimeSearch).format('D-MMM-YY') : ''
    const toTime = toDateTimeSearch ? moment(toDateTimeSearch).format('D-MMM-YY') : ''

    const params = {
      page: currentPage,
      from_time: fromTime,
      to_time: toTime,
      include: ['payment'],
    }
    if (scope === DRAFT.scope) {
      if (currentCustomer.current_company_id) {
        params.company_id = currentCustomer.current_company_id
      }
      return params
    }

    let searchTypeHandle = 'all'
    let filterKey = searchFuzzySearch

    if (currentCustomer.current_company_id) {
      params['company_ids'] = [currentCustomer.current_company_id]
    }
    if (searchOption === BOOKING_TYPE.value) {
      filterKey = {}
      switch (searchFuzzySearch || typeBooking) {
        case I18n.t(TYPE_WHOLE_VEHICLE.value):
          searchTypeHandle = 'ftl'
          break;
        case I18n.t(TYPE_PARTIAL_LOAD.value):
          searchTypeHandle = 'ltl'
          break;
        case I18n.t(TYPE_CONTAINER_LOAD.value):
          searchTypeHandle = 'lcl'
          break;
        default:
      }
    }
    return {
      ...params,
      scope,
      ...(searchOption && searchOption !== BOOKING_TYPE.value
        ? { filter_option: searchOption }
        : {}),
      ...(filterKey ? { filter_key: filterKey } : {}),
      ...(typeBooking || searchOption === BOOKING_TYPE.value
        ? { booking_type: searchTypeHandle }
        : {}),
      sort_by: SCOPE_SORT_BY[scope],
      sort_dir: scope === UPCOMING.scope ? 'asc' : 'desc',
    }
  },
}
export { ClosuresUtils as default }

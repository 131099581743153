import React, { useEffect, useState } from 'react'
import commonUtils from 'utils/common'
import { FACEBOOK_ICON, GOOGLE_ICON, V2_ICON_GREEN, V2_ICON_GREEN_TRANSPORTIFY } from 'constants/imageConstants'
import { FACEBOOK_ID, GOOGLE_KEY } from 'constants/appConstants'
import { hideLoading, showLoading } from 'assets/javascripts/webapp-v2/common'
import { useTranslation } from 'react-i18next'
import FormContainer from 'components/common/FormInput'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import SButton from 'components/common/Styled/SButton'
import ModalNormal from 'components/common/Modal/ModalNormal'
import { theme } from 'components/common/Styled/STheme'
import SFlex from 'components/common/Styled/SLayout/SFlex'
import SCommon from 'components/common/Styled/SCommon/SCommon'
import STypography from 'components/common/Styled/STypography'
import { useAppDispatch, useAppSelector } from 'store/store'
import accountAction, { IParamSignUp } from 'store/actions/common/accountAction'
import { REVOLUTION_MODAL } from 'components/new_booking/guest_flow/constants'
import SIcon from 'components/common/Styled/SIcon'
import SocialButton from '../SocialButton'
import { IFormItem, TYPE_INPUT } from 'components/common/FormInput/FormItem'
import { ErrorWrapper, SignUpWrapper } from './SSignUp'
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom'
import { SIGN_IN_MODAL } from 'components/new_booking/guest_flow/constants'
import paths from 'routers/paths'
import { SocialInfo } from '..'
import CommonUtils from 'utils/common'
import moment from 'moment'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import Modal from 'components/common/Modal'
import toastr from 'utils/toast'

interface ISignUpModal {
  onCheckRedirectPage: () => void
  onSetDataSignUp: React.Dispatch<React.SetStateAction<IParamSignUp>>
  onSocialLogin?: (
    socialInfo: SocialInfo,
    err: {
      error: string
    }
  ) => void
  customerSignInDirect?: boolean
  dataSignUp?: IParamSignUp
  isSignUpSocial: boolean
  isNoStep3?: boolean
  onCheckBackToStep1: () => void
}

const SignUp: React.FC<ISignUpModal> = ({
  onSocialLogin,
  onCheckRedirectPage,
  dataSignUp,
  isSignUpSocial,
  onSetDataSignUp,
  isNoStep3,
  onCheckBackToStep1,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const methods = useForm({
    defaultValues: {
      email: dataSignUp?.email,
      phone: dataSignUp?.phone,
      firstName: dataSignUp?.first_name,
      lastName: dataSignUp?.last_name,
      password: dataSignUp?.password,
      businessName: dataSignUp?.business_name,
      referredBy: dataSignUp?.referred_by,
    },
    resolver: yupResolver(
      yup.object({
        email: yup.string().trim().required(t('validations.required_email')).email(t('validations.email')),
        phone: yup.string().trim().required(t('validations.required_phone')),
        firstName: yup.string().trim().required(t('validations.required')),
        lastName: yup.string().trim().required(t('validations.required')),
        password: yup
          .string()
          .trim()
          .required(t('validations.required_password'))
          .min(6, t('validations.minlength', { number: 6 })),
      })
    ),
  })
  const dispatch = useAppDispatch()
  const [errorSignup, setErrorSignup] = useState('')
  const extraInfos = useAppSelector((state) => state.extraInfos)
  const { pathname } = useLocation()
  const currentStep = useAppSelector((state) => state.currentStep)
  const termsAndConditionsLink = extraInfos?.tc_link?.non_bp || ''
  const privacyPolicyLink = extraInfos?.privacy_policy_link || ''
  const LOGO_ICON = commonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN
  const arrayFormItem: IFormItem[] = [
    {
      label: t('devise_template.email_address'),
      name: 'email',
      type: TYPE_INPUT.INPUT,
      required: true,
      placeholder: '',
      colum: 12,
    },
    {
      label: t('devise_template.sign_up_mobile_phone'),
      name: 'phone',
      placeholder: '',
      colum: 12,
      type: TYPE_INPUT.PHONE,
      className: 'contact-input',
    },
    {
      label: t('devise_template.first_name'),
      name: 'firstName',
      type: TYPE_INPUT.INPUT,
      required: true,
      placeholder: '',
      colum: 12,
    },
    {
      label: t('devise_template.last_name'),
      name: 'lastName',
      type: TYPE_INPUT.INPUT,
      required: true,
      placeholder: '',
      colum: 12,
    },
    {
      label: t('devise_template.business_name'),
      name: 'businessName',
      type: TYPE_INPUT.INPUT,
      required: true,
      placeholder: '',
      colum: 12,
    },
    {
      label: t('devise_template.referred_by'),
      name: 'referredBy',
      type: TYPE_INPUT.INPUT,
      required: true,
      placeholder: '',
      colum: 12,
    },
    {
      label: t('devise_template.sign_up_password'),
      name: 'password',
      type: TYPE_INPUT.INPUT,
      required: true,
      placeholder: '',
      secured: true,
      colum: 24,
    },
  ]

  const handleSubmitSignUp = async (params: IParamSignUp) => {
    showLoading()
    const dataSubmit = {
      country_code: extraInfos.country_code?.toLowerCase() || '',
      email: params.email,
      phone: params.phone,
      first_name: params.first_name,
      last_name: params.last_name,
      business_name: params.business_name,
      referred_by: params.referred_by,
      password: params.password,
      access_token: params.access_token,
      provider: params.provider,
      invitation_token: extraInfos.invitation_token,
    }
    if (!params.provider) delete dataSubmit.provider
    if (extraInfos?.enable_sms_verification) {
      hideLoading()
    }
    if (isSignUpSocial) {
      params.identity = extraInfos.identity
    }
    const response = await accountAction.signUp(dataSubmit)(dispatch)
    if (!response.accessToken) {
      const error = response.message ? response : { message: response.error }
      hideLoading()
      return setErrorSignup(error.message || '')
    }
    onSetDataSignUp(dataSubmit)
    const {
      id,
      last_name: lastName,
      first_name: firstName,
      name,
      email,
      phone,
      current_company_id: companyID,
    } = response
    const removePlusInPhone = phone && phone.slice(1)
    await CommonUtils.moengageUserAttribute(
      id,
      firstName,
      lastName,
      name,
      email,
      removePlusInPhone,
      companyID,
      response.country_code,
      false
    )
    try {
      await CommonUtils.moengageTrackEvent('Customer Signup', {
        'Registration Date': moment(new Date()).format('Y-MM-DDTHH:mm:ssZ'),
        'At Step': CommonUtils.getAtStepAttributeMoengage(currentStep),
      })
    } finally {
      hideLoading()
      if (extraInfos?.enable_sms_verification) {
        dispatch(accountManageActions.updateModalAccountManage(REVOLUTION_MODAL))
      } else {
        dispatch(accountManageActions.updateModalAccountManage(''))
        onCheckRedirectPage()
        onCheckBackToStep1()
      }
    }
  }

  useEffect(() => {
    methods.reset({
      email: dataSignUp?.email,
      phone: dataSignUp?.phone,
      firstName: dataSignUp?.first_name,
      lastName: dataSignUp?.last_name,
      password: dataSignUp?.password,
      businessName: dataSignUp?.business_name,
      referredBy: dataSignUp?.referred_by,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSignUp])

  return (
    <>
      <ModalNormal
        topLevel={!isNoStep3}
        closeIcon={
          !isNoStep3 && (
            <SButton
              br={`1px solid ${theme.colors.green[1]}`}
              h="30px"
              w="30px"
              rds="50%"
              variant="g3"
              onClick={() => dispatch(accountManageActions.updateModalAccountManage(''))}
            >
              <SIcon color={theme.colors.green[1]} fs={16} p={4} value="close" />
            </SButton>
          )
        }
        closeVariant="g3"
        customFixedWidth={'632px'}
        backgroundColor={theme.colors.white[1]}
        isOpen
      >
        <SignUpWrapper>
          <SFlex pt={10} pb={15} center>
            <STypography fs={17} color={theme.colors.gray[5]}>
              {t('devise_template.already_a_member', { app_name: commonUtils.appName() })}
            </STypography>
            &nbsp;
            {matchPath(pathname || '', paths.SIGN_UP) ? (
              <Link className="text-signin" to="/customers/sign_in">
                {t('devise_template.log_in_here')}
              </Link>
            ) : (
              <STypography
                className="cur-pointer text-signin"
                onClick={() => dispatch(accountManageActions.updateModalAccountManage(SIGN_IN_MODAL))}
              >
                {t('devise_template.log_in_here')}
              </STypography>
            )}
          </SFlex>
          <FormContainer
            colGap={20}
            arrayFormItem={arrayFormItem}
            methods={methods}
            onSubmit={(e) =>
              handleSubmitSignUp({
                email: e?.email,
                phone: e?.phone,
                first_name: e?.firstName,
                last_name: e?.lastName,
                password: e?.password,
                business_name: e?.businessName,
                referred_by: e?.referredBy,
                access_token: dataSignUp?.access_token || '',
                provider: dataSignUp?.provider || '',
              })
            }
          >
            <SFlex mt={5} col>
              <SButton h="36px" fs={15} mb={10} mt={10} variant="g2" type="submit">
                {t('devise_template.create_my_account')}
              </SButton>
              <SFlex center>
                <STypography
                  fs={13}
                  mt={5}
                  className="term-link"
                  dangerouslySetInnerHTML={{
                    __html: t('devise_template.by_clicking', {
                      term_link: termsAndConditionsLink,
                      privacy_link: privacyPolicyLink,
                      app_name: commonUtils.appName(),
                    }),
                  }}
                />
              </SFlex>
            </SFlex>
            <SFlex center>
              <SCommon.StrikeWrapper w="400px" mt={15} h="40px" color={theme.colors.gray[9]} mb={15}>
                <SCommon.Strike fw={300} color={theme.colors.dark[4]} pl={15} pr={15} fs={13}>
                  {t('customers.sessions.new.or_sign_up_with')}
                </SCommon.Strike>
              </SCommon.StrikeWrapper>
            </SFlex>
            <SFlex className="social-login" icenter>
              <SocialButton
                w="100%"
                provider="facebook"
                name={t('customers.sessions.new.login_with_facebook')}
                icon={FACEBOOK_ICON}
                autoCleanUri
                appId={FACEBOOK_ID || ''}
                callback={onSocialLogin}
                className="facebook-login"
              />
              <SocialButton
                w="100%"
                provider="google"
                name={t('customers.sessions.new.login_with_google')}
                icon={GOOGLE_ICON}
                autoCleanUri
                appId={GOOGLE_KEY}
                callback={onSocialLogin}
                className="google-login"
              />
            </SFlex>
          </FormContainer>
        </SignUpWrapper>
      </ModalNormal>
      {errorSignup && (
        <Modal
          closeVariant="g1"
          customMinWidth={320}
          customFixedWidth={'320px'}
          imageTitle={LOGO_ICON}
          isOpen
          modalAction={
            <SFlex mt={10} between>
              <SButton variant="gr1" h="36px" fw={500} mr={5} w="50%" type="button" onClick={() => setErrorSignup('')}>
                {t('webapp.action.no_confirm')}
              </SButton>
              <SButton
                h="36px"
                onClick={() => {
                  setErrorSignup('')
                  navigate(paths.SIGN_IN)
                }}
                w="50%"
                variant="g3"
              >
                {t('webapp.action.login')}
              </SButton>
            </SFlex>
          }
        >
          <ErrorWrapper>
            <STypography lh={24} pt={15} cVar="w1" ml={5} bgColor={theme.colors.green[1]} align="center" fs={15}>
              {errorSignup}
            </STypography>
          </ErrorWrapper>
        </Modal>
      )}
    </>
  )
}

export default SignUp
